.ta-hint-text {
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  color: #505a5e;
  opacity: 0.5;
  margin-top: 6px;
}

.ta-hint-text.offset-left {
  margin-left: 24px;
}