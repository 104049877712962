.select-options__container {
  height: 14px;
  line-height: 14px;
  margin-top: 9px;
}

.select-options__container:not(:last-child) {
  padding-right: 4px;
}

.select-option {
  cursor: pointer;
  background: #edeeee;
  color: rgba(80, 90, 94, 0.7);
  font-weight: 500;
  border-radius: 7px;
  border: none;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.5px;
  margin-left: -9px;
  padding-right: 11px;
  padding-left: 11px;
}

.select-option:focus {
  outline: none; 
}

.select-option.active {
  background: #b0b5b6;
  color: #505a5e;
  z-index: 10;
  position: relative;
}