.ta-markdown {
  font-size: 13px;
  font-weight: 100;
  color: #505a5e;
  line-height: 18px;
}

.ta-markdown ul li {
  position: relative;
  padding-left: 30px;
  padding-bottom: 11px;
  font-size: 13px;
  font-weight: 100;
  color: #505a5e;
  padding-top: 2px;
  line-height: 16px;
}

.ta-markdown ul li:before {
  content: "\f058";
  font-family: "Font Awesome 5 Pro";
  font-weight: normal;
  font-size: 24px;
  color: #769851;
  position: absolute;
  left: 0;
  top: 2px;
}

.ta-markdown ul li:last-child {
  padding-bottom: 0;
}

.ta-markdown ol li {
  position: relative;
  padding-left: 30px;
  padding-bottom: 11px;
  font-size: 13px;
  font-weight: 100;
  color: #505a5e;
  padding-top: 3px;
  line-height: 16px;
  counter-increment: index;
}

.ta-markdown ol li:last-child {
  padding-bottom: 0;
}

.ta-markdown ol li:before {
  content: counter(index);
  width: 24px;
  height: 24px;
  border-radius: 24px;
  padding: 4px;
  text-align: center;
  background: #505a5e;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
