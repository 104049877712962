.ta-image-crop {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 3px;
}

.ta-image-crop:after {
  content: ' ';
  display: table;
  clear: both;
}

.ta-image-crop .ta-image-crop__buttons {
  width: 100%;
  position: relative;
  display: flex;
  justify-items: center;
  justify-content: space-between;
  align-items: center;
}

.ta-image-crop .ta-image-crop__buttons.center {
  justify-content: center;
}

.ta-image-crop .ta-image-crop__sliders {
  width: 100%;
  position: relative;
}

.ta-image-crop .ta-btn.ta-btn-delete {
  margin: 0;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  font-size: 11px;
  line-height: 23px;
  text-align: center;
  padding: 0;
}

.ta-image-crop .ta-image-crop__controls {
  padding: 4px;
  width: 100%;
  border: solid 1px rgba(182, 186, 189, 0.5);
  border-top: 0;
  border-radius: 0 0 3px 3px;
}

.ta-image-crop__rotate-left {
  display: flex;
  align-items: center;
}

.ta-image-crop__rotate-right {
  display: flex;
  align-items: center;
}

.ta-image-crop .ta-single-slider__content {
  margin-top: 8px;
  display: flex;
  height: 16px;
  align-items: center;
}

.ta-image-crop .ta-single-slider__left-label, .ta-image-crop .ta-single-slider__right-label {
  transform: none;
}

.ta-image-crop .ta-image-crop__slider__icon {
  font-size: 12px;
}

.ta-image-crop canvas {
  border-radius: 3px 3px 0 0;
}

.ta-image-crop .ta-image-crop-upload-wrapper {
  width: 100%;
  height: 164px;
  color: #fff;
  overflow: hidden;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  cursor: pointer;
  text-align: center;
  border-radius: 3px;
  background-color: #f4f3f3;
  padding: 16px;
}

.ta-image-crop-upload-area {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px dashed #505a5e;
  border-radius: 4px;
}

.ta-image-crop .ta-image-crop-upload-area__image {
  width: auto;
  height: 100%;
  z-index: 1;
}

.ta-image-crop .ta-image-crop-upload-area__icon {
  font-size: 24px;
  color: #505a5e;
  margin-bottom: 10px;
}

.ta-image-crop .ta-image-crop-upload-area__title {
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #505a5e;
  margin: 2px;
}

.ta-image-crop .ta-image-crop-upload-area__text {
  width: 100%;
  opacity: 0.7;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #505a5e;
  margin: 2px;
}

.ta-image-crop-upload-area__formats-container {
  display: flex;
  margin: 2px;
}

.ta-image-crop .ta-image-crop-upload-area__format {
  width: 30px;
  height: 15px;
  border-radius: 100px;
  text-align: center;
  vertical-align: middle;
  font-size: 9px;
  font-weight: 500;
  font-style: normal;
  line-height: 14px;
  color: #505a5e;
  text-transform: uppercase;
  border: 1px solid #505a5e;
  margin: 2px;
}

.ta-image-crop .ta-image-crop__dropzone {
  width: 100%;
  height: 100%;
}

.ta-image-crop .ta-image-crop__avatar-editor {
  width: 100%;
  height: 100%;
}

.ta-image-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.ta-image-wrapper img {
  width: 100%;
  height: 100%;
}

.ta-btn-full-width {
  width: 100% !important;
}

.ta-btn-full-width-label {
  font-size: 10px;
  font-weight: 500;
  padding-left: 4px;
}

.ta-cropper-wrapper {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 210px;
  width: 100%;
  border-radius: 3px 3px 0 0;
  overflow: hidden;
  background: #fff;
  border: 1px solid rgba(182, 186, 189, 0.5);
  border-bottom: none;
}

.ta-cropper-container {
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  border-radius: 3px;
  overflow: hidden;
}

.ta-image-crop-preview {
  position: relative;
  border-radius: 4px;
  box-shadow: 0 0 10px #000;
  margin: 10px;
  box-sizing: border-box;
}

.ta-image-crop-preview img {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  margin-bottom: -2px;
}

.ta-current-image-title-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 32px;
  background-color: rgba(29, 50, 71, 0.8);
  overflow: hidden;
}

.ta-current-image-title {
  width: calc(100% - 32px);
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 31px;
  letter-spacing: normal;
  color: #ffffff;
  padding-left: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
