.ta-fixed-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ta-fixed-content__header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 15px;
  z-index: 100;
}

.ta-fixed-content__body {
  position: absolute;
  top: 52px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  overflow-x: hidden;
  padding: 0 15px;
}

.ta-fixed-content:before {
  content: "";
  position: fixed;
  top: 52px;
  left: 15px;
  right: 15px;
  z-index: 10;
  height: 10px;
  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( endColorstr='#f4f3f3', startColorstr='#00ffffff',GradientType=0 );
}

/* FIXED FORM BUTTONS */

.ta-fixed-content.fixed-form-buttons .ta-form-buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 15px 15px;
  background: #fff;
  z-index: 1000;
}

.ta-fixed-content.fixed-form-buttons .ta-form-buttons:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  height: 30px;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f4f3f3', GradientType=0);
}

.ta-fixed-content.fixed-form-buttons .ta-fixed-content__body {
  padding-bottom: 40px;
}

/* IE FIX */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ta-fixed-content:before {
    position: absolute;
  }
  .ta-fixed-content.fixed-form-buttons .ta-form-buttons {
    position: static;
    padding: 0;
  }
  .ta-fixed-content.fixed-form-buttons .ta-fixed-content__body {
    padding-bottom: 0;
  }
}
