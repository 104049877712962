.ta-divider::after{
  content: '';
  position: absolute;
  width: 45%;
  top: 45%;
  right: 0;
  height: 1px;
  background-color: #dadcde;
}

.ta-divider::before{
  content: '';
  position: absolute;
  width: 45%;
  top: 45%;
  left: 0;
  height: 1px;
  background-color: #dadcde;
}

.ta-divider{
  width: 100%;
  text-align: center;
  position: relative;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: normal;
  color: #505a5e;
  margin: 10px 0;
}