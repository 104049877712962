.ta-multiple-select .Select-control {
  height: 32px;
  line-height: 30px;
  border: 1px solid rgba(80, 90, 94, 0.6);
  background: transparent;
  font-size: 13px;
  font-weight: 300;
  color: #505a5e;
  padding: 0 10px;
  border-radius: 3px;
  transition: all 0.5s;
  box-shadow: none;
  position: relative;
  z-index: 2;
}

/* .Select.is-focused:not(.is-open) > .Select-control */

.ta-multiple-select.is-focused:not(.is-open)>.Select-control {
  box-shadow: none;
  border-color: #385f87;
  background-color: rgba(56, 95, 135, 0.1);
}

.ta-multiple-select.is-open .Select-control {
  border-radius: 3px 3px 0 0;
  background-color: rgb(235, 239, 243);
  box-shadow: 0 5px 10px 0 rgba(56, 95, 135, 0.15);
}

.ta-form-group.filled .ta-multiple-select .Select-control {
  border-color: #505a5e;
}

.ta-form-group.filled .ta-multiple-select.is-focused .Select-control,
.ta-multiple-select.is-focused .Select-control {
  border-color: #385f87;
  background-color: rgba(56, 95, 135, 0.1);
}

.ta-multiple-select .Select-input {
  height: 27px;
  line-height: 27px;
  padding-left: 0;
  vertical-align: top;
  margin-left: 0;
  z-index: 2;
}

.ta-multiple-select__placeholder {
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  font-size: 13px;
  font-weight: 300;
  color: #505a5e;
  z-index: 1;
  opacity: 0.5;
}

.ta-form-group.focused .ta-multiple-select__placeholder {
  display: none;
}

.ta-multiple-select.has-value .Select-input {
  margin-left: 0;
}

.ta-multiple-select .Select-control .Select-placeholder {
  line-height: 30px;
  display: none;
}

.ta-multiple-select .Select-control .Select-value {
  line-height: 20px;
  margin: 2px;
  background: #505a5e;
  font-weight: 500;
  opacity: 0.7;
  border-radius: 3px;
  color: #fff;
  display: none;
}

.ta-multiple-select .Select-value-icon {
  float: right;
  border: none;
  font-weight: 500;
  font-size: 16px;
  padding: 1px 5px 3px 1px;
}

.ta-multiple-select .Select-value-icon:hover {
  color: #fff;
}

.ta-multiple-select .Select-clear-zone {
  width: 25px;
  position: relative;
}

.ta-multiple-select .Select-clear {
  font-size: 0;
}

.ta-multiple-select .Select-clear:after {
  content: "\f00d";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 11px;
  color: #505a5e;
  transform: translate(-50%, -50%);
  opacity: 0.7;
}

.ta-multiple-select .Select-arrow-zone {
  padding-right: 0;
  position: relative;
  width: 10px;
}

.ta-multiple-select .Select-arrow-zone i.fa-angle-up {
  position: absolute;
  top: 5px;
  right: 0;
}

.ta-multiple-select .Select-arrow-zone i.fa-angle-down {
  position: absolute;
  bottom: 5px;
  right: 0;
}

.ta-multiple-select .Select-menu-outer {
  margin-top: 1px;
  box-shadow: none;
  border: 1px solid #385f87;
  border-radius: 0 0 3px 3px;
  background: #f6f7f9;
  padding: 5px;
  z-index: 100;
  background-color: rgb(235, 239, 243);
  box-shadow: 0 5px 10px 0 rgba(56, 95, 135, 0.15);
  max-height: 300px;
}

.ta-multiple-select .Select-option {
  border-bottom: 1px dashed #385f87;
  font-size: 13px;
  font-weight: 300;
  color: #969c9e;
  background: transparent;
  padding: 0 5px;
  height: 28px;
  line-height: 28px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ta-multiple-select .Select-option:before {
  content: "";
  border-top: 1px solid rgb(235, 239, 243);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.ta-multiple-select .Select-option:after {
  content: "";
  border-top: 1px solid rgb(235, 239, 243);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.ta-multiple-select .Select-option:hover {
  background-color: rgba(56, 95, 135, 0.1);
  color: #505a5e;
}

.ta-multiple-select .Select-option:last-child {
  border-bottom: none;
}

.ta-multiple-select .Select-option.is-selected {}

.ta-multiple-select .Select-option.is-focused {
  background-color: rgba(56, 95, 135, 0.1);
  color: #505a5e;
}

.ta-multiple-select .Select-noresults {
  font-size: 11px;
  color: #969c9e;
  font-weight: 100;
}

.ta-multiple-select__values-item {
  display: inline-block;
  opacity: 0.7;
  border-radius: 3px;
  background-color: #505a5e;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  padding-left: 8px;
  margin: 5px 5px 0 0;
  cursor: default;
}

.ta-multiple-select__values-item:hover {
  opacity: 1;
}

.ta-multiple-select__values-item-delete {
  float: right;
  padding: 0 8px;
  cursor: pointer;
  height: 26px;
  line-height: 26px;
}

.ta-multiple-select__values-item-label {
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 210px;
  white-space: nowrap;
  height: 26px;
  line-height: 26px;
}

.ta-multiple-select__values-item-avatar {
  float: left;
  margin-top: 3px;
  margin-left: 3px;
}

.ta-multiple-select__values-item-avatar .ta-avatar {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 9px;
  margin: 0;
  padding: 0;
  margin-right: 4px;
  margin-left: -5px;
  vertical-align: middle;
}

.ta-multiple-select__values.inline {
  width: fit-content;
  position: absolute;
  top: 6px;
  left: 6px;
  right: 6px;
}

.ta-multiple-select__values.inline .ta-multiple-select__values-item {
  height: 20px;
}

.ta-multiple-select__values.inline .ta-multiple-select__values-item-delete {
  height: 20px;
  line-height: 20px;
}

.ta-multiple-select__values.inline .ta-multiple-select__values-item-label {
  height: 20px;
  line-height: 20px;
}

.ta-multiple-select__values.inline .ta-multiple-select__values-item-avatar {
  margin-top: 2px;
}

.ta-multiple-select__values.inline .ta-avatar {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
