.ta-login__box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
}

.ta-login__box .ta-form {
  padding: 0;
}

.ta-login__box-content {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.3);
  padding: 20px;
  position: relative;
  z-index: 2;
}

.ta-login__box-content .ta-loader {
  /* bottom: 50px; */
  border-radius: 3px;
}

.ta-login__password-type-toggle-btn {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  top: 5px;
  right: 6px;
  cursor: pointer;
  font-size: 14px;
  color: #505a5e;
}

.ta-login__link {
  font-size: 12px;
  font-weight: 500;
  color: #505a5e;
  opacity: 0.5;
  line-height: 32px;
}

.ta-login__link:hover {
  opacity: 1;
}

.ta-login__title {
  margin: -18px -18px 20px -18px;
  height: 60px;
  line-height: 60px;
  background: #e4e6e8;
  font-size: 16px;
  font-weight: 500;
  color: #505a5e;
  border-radius: 3px 3px 0 0;
  text-align: center;
}

.ta-login__box-footer {
  position: relative;
  z-index: 1;
  background: #efefef;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.3);
  border-radius: 0 0 3px 3px;
  margin: 0 20px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.ta-login__box-footer a {
  font-size: 12px;
  font-weight: 500;
  color: #505a5e;
  display: block;
}

.ta-login__box-footer a:hover {
  text-decoration: underline;
}

.ta-login__box .ta-message.type-warning {
  text-align: center;
}

.ta-login__box .ta-message.type-warning p {
  text-align: left;
}

.ta-login__box .ta-message.type-warning .ta-message__icon {
  top: calc(50% - 25px);
}

.ta-login__sso-btn {
  margin-top: 8px;
}

/* MIGRATION OVERLAY */

.ta-migration-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background: -moz-linear-gradient(top, #385f87 0%, #1d3247 100%);
  background: -webkit-linear-gradient(top, #385f87 0%, #1d3247 100%);
  background: linear-gradient(to bottom, #385f87 0%, #1d3247 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( endColorstr='#1d3247', startColorstr='#385f87', GradientType=0);
  visibility: hidden;
  opacity: 0;
  transition: all 0.8s;
}

.ta-migration-overlay.active {
  visibility: visible;
  opacity: 1;
}

.ta-migration-overlay__message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: 20px;
  text-align: center;
  line-height: 20px;
}

.ta-migration-overlay__message .ta-spinner {
  color: #fff;
  position: static;
  top: 0;
  left: 0;
  transform: none;
  font-size: 30px;
  margin-bottom: 20px;
}
