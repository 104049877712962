.ta-service-combination-select__select {
  margin-bottom: 8px;
}

.ta-service-combination-select__selected-item__dnd-wrapper {
  margin-bottom: 4px;
}

.ta-service-combination-select__selected-item {
  background: #848B8E;
  border-radius: 3px;
  height: 24px;
  line-height: 24px;
  color: #fff;
  font-size: 12px;
  position: relative;
  user-select: none;
}

.ta-service-combination-select__selected-item.is-deleted {
  background: rgba(80, 90, 94, 0.3);
}

.ta-service-combination-select__selected-item__index {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 32px;
  text-align: center;
  background: #d3d6d7;
  color: rgba(80, 90, 94, 0.6);
  border-radius: 3px 0 0 3px;
}

.ta-service-combination-select__selected-item__body {
  position: relative;
  font-weight: 500;
  margin-left: 38px;
  margin-right: 24px;
}

.ta-service-combination-select__selected-item__body:after {
  content: "\f07d";
  font-family: "Font Awesome 5 Pro";
  font-size: 11px;
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
  opacity: 0.7;
}

.ta-service-combination-select__selected-item__body span {
  font-size: 8px;
  text-transform: uppercase;
  background: #a7acae;
  border-radius: 10px;
  height: 12px;
  line-height: 13px;
  display: inline-block;
  padding: 0 4px;
  vertical-align: middle;
  transform: translateY(-1px);
  margin-left: 5px;
}

.ta-service-combination-select__selected-item__delete-btn {
  width: 24px;
  height: 24px;
  line-height: 25px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
}

.ta-service-combination-select__selected-item__dnd-wrapper {
  left: 15px!important;
}

.ta-service-combination-select__selected-item__dnd-wrapper.is-dragging {
  opacity: 0.8;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.5);
}
