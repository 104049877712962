.ta-agents__title {
  padding: 20px 0 15px;
}

.ta-agents .ta-title__content .ta-btn,
.ta-agents__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-agents .ta-title__content > :not(:first-child),
.ta-agents__preview .ta-title__content > :not(:first-child) {
  margin-left: 4px;
}

.ta-agents .ta-title__content .ta-title__link {
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 4px;
  display: inline-block;
}

.ta-agents .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-agents .ta-fixed-content:before,
.ta-agents .ta-fixed-content__body {
  top: 104px;
}

.ta-agents .ta-fixed-content:before {
  z-index: 900;
}

.ta-agents .ta-fixed-content__body {
  padding-bottom: 15px;
  padding-top: 5px;
}

.ta-agents__list {
  padding: 10px 0;
}

.ta-agents__agent {
  position: relative;
  display: inline-block;
  width: 25%;
  padding: 10px 5px;
  vertical-align: top;
  text-align: center;
}

.ta-agents .ta-fixed-content__header {
  z-index: 998;
}

.ta-agents__agent.updated {
  position: relative;
}

.ta-agents__agent.updated:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  opacity: 0;
  animation-duration: 2s;
  animation-name: resource-updated-before;
  animation-iteration-count: 1;
}

@keyframes resource-updated-before {
  0% {
    opacity: 1;
    background: rgba(244, 243, 243, 1);
  }

  100% {
    opacity: 0;
    background: rgba(244, 243, 243, 0);
  }
}

.ta-agents__agent.updated:after {
  content: "\f021";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 44px;
  transform: translateX(-50%);
  z-index: 11;
  opacity: 0;
  animation-duration: 2s;
  animation-name: resource-updated-after;
  animation-iteration-count: 1;
}

@keyframes resource-updated-after {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ta-agents__agent.deleted {
  position: relative;
}

.ta-agents__agent.deleted:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  animation-duration: 2s;
  animation-name: resource-deleted-before;
  animation-iteration-count: 1;
}

@keyframes resource-deleted-before {
  0% {
    background: rgba(244, 243, 243, 0);
  }

  15% {
    background: rgba(244, 243, 243, 0.7);
  }

  100% {
    background: rgba(244, 243, 243, 1);
  }
}

.ta-agents__agent.deleted:after {
  content: "\f1f8";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 44px;
  transform: translateX(-50%);
  z-index: 11;
  animation-duration: 2s;
  animation-name: resource-deleted-after;
  animation-iteration-count: 1;
}

@keyframes resource-deleted-after {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ta-agents__list .ta-avatar {
  margin-bottom: 8px;
}

.ta-agents__agent__label.owner {
  border: 2px solid #f4f3f3;
  background: #ecd007;
  font-size: 13px;
  color: #505a5e;
  width: 30px;
  height: 30px;
  line-height: 26px;
  text-align: center;
  border-radius: 30px;
  position: absolute;
  top: 10px;
  right: 15px;
}

.ta-agents__agent__label.admin {
  border: 2px solid #f4f3f3;
  background: #cb3b43;
  font-size: 11px;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 26px;
  text-align: center;
  border-radius: 30px;
  position: absolute;
  top: 10px;
  right: 15px;
}

.ta-agents__agent__label.custom {
  border: 2px solid #fff;
  background: #385f87;
  font-size: 11px;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 26px;
  text-align: center;
  border-radius: 30px;
  position: absolute;
  top: 10px;
  right: 15px;
}

.ta-agents__agent .ta-offline .ta-offline-body,
.ta-agents__agent .ta-offline {
  max-width: 100%;
}

.ta-agents__agent .ta-offline .ta-agents__agent__label.owner,
.ta-agents__agent .ta-offline .ta-agents__agent__label.custom,
.ta-agents__agent .ta-offline .ta-agents__agent__label.admin {
  top: 0;
  right: 0;
}

.ta-agents__agent .ta-offline .ta-offline-icon {
  height: 86px;
}

.ta-agents__agent-status {
  display: inline-block;
  padding: 0 5px;
  margin: 0 auto;
  margin-top: 3px;
  height: 12px;
  line-height: 14px;
  text-align: center;
  border-radius: 10px;
  background: #b6babd;
  font-size: 8px;
  font-weight: 500;
  color: #505a5e;
  text-transform: uppercase;
}

.ta-agents__agent-status.active {
  background: #769851;
  color: #fff;
}

.ta-agents__agent-status.has-online-warning {
  background: #ecd007;
  color: #505a5e;
}

.ta-agents__agent-name {
  font-size: 11px;
  font-weight: 500;
  color: #505a5e;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  min-height: 12px;
}

.ta-avatar__add-btn {
  background: transparent;
  border: 1px solid #cb3b43;
}

.ta-avatar__add-btn i{
  color: #cb3b43;
}

.ta-agents__agent-status__add-btn {
  color: #b6babd;
  text-transform: initial;
  background: transparent;
  border: 1px solid #b6babd;
  line-height: 12px;
}

.ta-agents__agent__add-btn.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.ta-agents__agent.ta-agents__agent__add-btn .ta-agents__agent-name{
  color: #cb3b43;
}


.ta-agents__preview .ta-fixed-content__body {
  padding-top: 15px;
}

.ta-agents__category-type {
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  height: 16px;
  line-height: 16px;
  background: #769851;
  color: #fff;
  padding: 0 5px;
  margin-right: 5px;
  border-radius: 3px;
  font-size: 10px;
  transform: translateY(-2px) translateZ(0);
  z-index: 10;
}

.ta-agents__category-type.dependency {
  background: #ecd007;
  color: #505a5e;
}

.ta-agents__category-type.global {
  background: transparent;
  color: #505a5e;
  border: 1px solid #b6babd;
  line-height: 1.4; 
}

.ta-agents__category-type.global.edited {
  border: solid 1px rgba(235, 182, 93, 0.4);
  background-color: rgba(235, 182, 93, 0.2); 
}

.ta-agents__category-type.global.edited i {
  margin-right: 2px;
}

.ta-agents__category {
  position: relative;
}

.ta-agents__category.pending:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f4f3f3;
  opacity: 0.5;
  z-index: 99;
}

.ta-agents__category.pending:after {
  content: "\f110";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  left: calc(50% - 14px);
  top: calc(50% - 14px);
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  color: #505a5e;
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
  z-index: 100;
}

.ta-agents__category.deleted {
  position: relative;
}

.ta-agents__category.deleted:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  animation-duration: 2s;
  animation-name: resource-category-deleted-before;
  animation-iteration-count: 1;
}

@keyframes resource-category-deleted-before {
  0% {
    background: rgba(244, 243, 243, 0.5);
  }

  15% {
    background: rgba(244, 243, 243, 0.7);
  }

  100% {
    background: rgba(244, 243, 243, 1);
  }
}

.ta-agents__category.deleted:after {
  content: "\f1f8";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 44px;
  transform: translateX(-50%);
  z-index: 12;
  animation-duration: 2s;
  animation-name: resource-category-deleted-after;
  animation-iteration-count: 1;
}

@keyframes resource-category-deleted-after {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ta-agents__category__delete-btn {
  opacity: 0.5;
}

.ta-agents__category .ta-section-title {
  padding-right: 90px;
}

.ta-agents__category.no-edit-btn .ta-section-title,
.ta-agents__category.no-add-btn .ta-section-title {
  padding-right: 60px;
}

.ta-agents__category.no-add-btn.no-edit-btn .ta-section-title {
  padding-right: 30px;
}

.ta-agents__category .ta-section-title__label {
  max-width: calc(100% - 70px);
}

.ta-agents .ta-agents__category.has-buttons .ta-accordion .ta-accordion__title:after,
.ta-agents .ta-agents__category.has-buttons.edited-default.default .ta-accordion .ta-accordion__title:after {
  right: 90px;
}

.ta-agents .ta-agents__category.has-buttons.no-edit-btn .ta-accordion .ta-accordion__title:after,
.ta-agents .ta-agents__category.has-buttons.no-add-btn .ta-accordion .ta-accordion__title:after,
.ta-agents .ta-agents__category.has-buttons.edited-locally .ta-accordion .ta-accordion__title:after,
.ta-agents .ta-agents__category.has-buttons.default .ta-accordion .ta-accordion__title:after {
  right: 62px;
}

.ta-agents .ta-agents__category.has-buttons.no-edit-btn.default .ta-accordion .ta-accordion__title:after,
.ta-agents .ta-agents__category.has-buttons.no-add-btn.default .ta-accordion .ta-accordion__title:after,
.ta-agents .ta-agents__category.has-buttons.no-add-btn.no-edit-btn .ta-accordion .ta-accordion__title:after,
.ta-agents .ta-agents__category.has-buttons.edited-locally.no-add-btn .ta-accordion .ta-accordion__title:after {
  right: 32px;
}

/* .ta-agents .ta-agents__category.has-buttons.no-add-btn.no-edit-btn .ta-accordion .ta-accordion__title:after {
  right: 32px;
} */

/* .ta-agents .ta-agents__category.has-buttons.edited-locally .ta-accordion .ta-accordion__title:after {
  right: 62px;
} */

.ta-agents .ta-agents__category.has-buttons.no-add-btn.no-edit-btn.default .ta-accordion .ta-accordion__title:after {
  right: 2px;
}

/* .ta-agents .ta-agents__category.has-buttons.default .ta-accordion .ta-accordion__title:after {
  right: 62px;
} */

.ta-agents__list__total-agents {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: bold;
  padding: 6px 8px;
  font-size: 10px;
  line-height: 1.2;
  letter-spacing: 0.5px;
  color: #505A5E;
  margin-bottom: 8px;
  border-radius: 3px;
  height: 24px;
  background-color: #E5E7E7;
}

.ta-agents__list__total-agents .numbers {
  opacity: 0.7;
}

.ta-agents__available-agents {
  width: 72px;
  padding: 0 4px;
  border-radius: 40px;
  border: solid 1px #b6babd;
  font-size: 8px;
  font-weight: bold;
  line-height: 1.7;
  letter-spacing: 0.5px;
  color: rgba(80, 90, 94, 0.5);
  text-transform: uppercase;
  margin: 0 auto;
  margin-top: 4px;
}