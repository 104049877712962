.ta-circular-countdown {
    position: relative;
}

.ta-circular-countdown svg {
    box-sizing: border-box;
}

.ta-circular-countdown circle {
    stroke-width: 8px;
    stroke: #e5e6e7;
    fill: none;
}

.ta-circular-countdown path {
    stroke-linecap: round;
    stroke-width: 8px;
    stroke: #385f87;
    fill: none;
}

.ta-circular-countdown.near-end path {
    stroke: #cb3b43;
}

.ta-circular-countdown__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0.5px;
    color: rgba(80, 90, 94, 0.7);
    text-transform: uppercase;
}

.ta-circular-countdown__content__count {
    font-size: 32px;
    font-weight: 500;
    color: #385f87;
}

.ta-circular-countdown.near-end .ta-circular-countdown__content__count {
    color: #cb3b43;
}
