.ta-booking-customer-select {
  position: relative;
}

.ta-booking-customer-select.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.ta-booking-customer-select.has-options.active .ta-form-control {
  border-radius: 3px 3px 0 0;
}

.ta-booking-customer-select__options {
  position: absolute;
  top: 37px;
  left: 0;
  right: 0;
  margin-top: 1px;
  box-shadow: none;
  border: 1px solid #385f87;
  border-radius: 0 0 3px 3px;
  background: #f6f7f9;
  padding: 5px;
  z-index: 100;
  background-color: rgb(235, 239, 243);
  box-shadow: 0 5px 10px 0 rgba(56, 95, 135, 0.15);
  max-height: 160px;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  margin-bottom: 10px;
}

.ta-booking-customer-select__options.pending {
  min-height: 40px;
}

.ta-booking-customer-select.active .ta-booking-customer-select__options {
  visibility: visible;
  opacity: 1;
}

.ta-booking-customer-select__options__item {
  border-bottom: 1px dashed #385f87;
  font-size: 13px;
  font-weight: 300;
  color: #969c9e;
  padding: 8px;
  height: 48px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
  color: #505a5e;
}

.ta-booking-customer-select__options__item:before {
  content: "";
  border-top: 1px solid rgb(235, 239, 243);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
 
.ta-booking-customer-select__options__item:after {
  content: "";
  border-top: 1px solid rgb(235, 239, 243);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.ta-booking-customer-select__options__item:last-child {
  border-bottom: none;
}
 
.ta-booking-customer-select__options__item.selected,
.ta-booking-customer-select__options__item:hover {
  background-color: rgba(56, 95, 135, 0.1);
}

.ta-booking-customer-select__options__item:hover .ta-booking-customer-select__options__name,
.ta-booking-customer-select__options__item:hover .ta-booking-customer-select__options__phone,
.ta-booking-customer-select__options__item:hover .ta-booking-customer-select__options__email,
.ta-booking-customer-select__options__item.selected .ta-booking-customer-select__options__name,
.ta-booking-customer-select__options__item.selected .ta-booking-customer-select__options__phone,
.ta-booking-customer-select__options__item.selected .ta-booking-customer-select__options__email {
  opacity: 1;
}

.ta-booking-customer-select__options__name {
  margin-bottom: 2px;
  line-height: 1.23;
  opacity: 0.6;
}

.ta-booking-customer-select__options__phone {
  display: inline-block;
  overflow: hidden;
  font-size: 11px;
  line-height: 1.27;
  width: calc(50% - 2px);
  opacity: 0.7;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 4px;
}

.ta-booking-customer-select__options__email {
  display: inline-block;
  overflow: hidden;
  font-size: 11px;
  line-height: 1.27;
  width: calc(50% - 2px);
  opacity: 0.7;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ta-booking-customer-select__options__phone i,
.ta-booking-customer-select__options__email i {
  margin-right: 4px;
}

.ta-booking-customer-select__options__new {
  font-size: 11px;
  font-style: italic;
  color: #385f87;
  line-height: 14px;
  padding: 5px;
  background: rgba(0,0,0,0.05);
  border-radius: 2px;
  cursor: pointer;
  padding-right: 30px;
  position: relative;
}

.ta-booking-customer-select__options__new:after {
  content: "\f067";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: #cb3b43;
  color: #fff;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%) translateZ(0);
}

.ta-booking-customer-select__options__new strong {
  /* margin-left: 4px; */
  word-wrap: break-word;
}

.ta-booking-customer-select__options__new.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.ta-booking-customer-select .ta-hover-popup {
  width: 100%;
}
