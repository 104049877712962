.ta-label-input .ta-form-error {
  margin: -4px 0 8px 0;
}

.ta-label-input .ta-form-group {
  margin-bottom: 10px;
  display: table;
  width: 100%;
  border: 1px solid rgba(80, 90, 94, 0.6);
  padding-top: 0;
  border-radius: 3px;
}

.ta-label-input .ta-form-group.filled {
  border-color: #505a5e;
}

.ta-label-input__prefix {
  display: table-cell;
  width: 80px;
  white-space: nowrap;
  padding: 0 10px;
  background: rgba(56, 95, 135, 0.1);
  font-weight: 100;
  font-size: 12px;
  border-radius: 2px 0 0 2px;
  color: #505a5e;
}

.ta-label-input .ta-form-control {
  border: none;
  border-radius: 0 3px 3px 0;
}

.ta-label-input .ta-form-group label {
  visibility: visible;
  opacity: 1;
  color: #505a5e;
  top: 6px;
  left: 8px;
  font-size: 9px;
  font-weight: bold;
  line-height: 13px;
}

.ta-label-input .ta-form-group input {
  padding-top: 15px;
}

.ta-label-input .ta-form-group label:after {
  border-top: 3px solid #EBEFF3;
}

.ta-label-input .ta-form-group.focused label:after {
  border-color: #D9E1E8;
}

.ta-label-input .ta-form-group.focused .ta-form-control {
  box-shadow: none;
}

.ta-label-input .ta-form-group.focused {
  border-color: #385f87;
  background-color: rgb(235, 239, 243);
  box-shadow: 0 5px 10px 0 rgba(56, 95, 135, 0.15);
}
