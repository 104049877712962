.ta-account-select__box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.3);
  padding: 20px 20px 0 20px;
}

.ta-account-select__box:before {
  /* content: ""; */
  position: absolute;
  top: 50px;
  left: 3px;
  right: 3px;
  height: 15px;
  z-index: 100;
  visibility: visible;
  opacity: 1;
  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( endColorstr='#f4f3f3', startColorstr='#00ffffff',GradientType=0 );
}

.ta-account-select__box:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 3px;
  right: 3px;
  height: 15px;
  z-index: 100;
  visibility: visible;
  opacity: 1;
  background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( endColorstr='#f4f3f3', startColorstr='#00ffffff',GradientType=0 );
}

.ta-account-form__enforce-password-change .ta-account-select__box__content {
  padding: 16px;
}

.ta-account-form__enforce-password-change .ta-account-select__box__content .ta-form {
  padding-bottom: 0;
}

.ta-account-form__enforce-password-change .ta-account-select__box__logout.ta-hover-popup a {
  height: 60px;
}

.ta-account-form__enforce-password-change .ta-account-select__box:before {
  content: unset;
}

.ta-account-form__enforce-password-change .ta-account-select__box:after {
  content: unset;
}

.ta-account-form__enforce-password-change .ta-account-select__box__title {
  text-align: center;
  height: 60px;
  overflow: hidden;
}

.ta-account-form__enforce-password-change .ta-btn.ta-btn-primary {
  width: 100%;
}

.ta-account-form__enforce-password-change .ta-account-select__box__content {
  max-height: unset;
}

.ta-account-form__enforce-password-change .ta-account-select__box__content a {
  cursor: pointer;
}

.ta-account-form__enforce-password-change .ta-account-select__box__content a:hover {
  text-decoration: none;
}

.ta-account-select__box__title {
  line-height: 1.5;
  margin: -18px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  color: #505a5e;

  height: 60px;
  padding: 18px 14px;
  background-color: #e7eaed;
}

.ta-account-select__box__title > i {
  margin-right: 10px;
}

.ta-account-select__box__content {
  margin: 0 -20px;
  padding: 10px 20px 0;
  max-height: 300px;
  overflow: auto;
}

.ta-account-select__box__content .ta-accounts__item:last-child {
  margin-bottom: 0;
}

.ta-account-select__box__content .ta-no-results {
  margin-bottom: 20px;
  margin-top: 10px;
}

.ta-account-select__box__content .ta-form {
  padding: 10px 0;
}

.ta-account-select__box__logout.ta-hover-popup {
  position: absolute;
  top: 7px;
  right: 1px;
  z-index: 101;
  line-height: 16px;
}

.ta-account-select__box__logout.ta-hover-popup a {
  display: block;
  color: #505a5e;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
}
