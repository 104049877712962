.ta-radio {
  display: inline-block;
  color: rgba(80, 90, 94, 0.6);
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  min-height: 20px;
  position: relative;
  padding-left: 25px;
  padding-top: 3px;
  user-select: none;
}

.ta-radio:after {
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  text-align: center;
  width: 20px;
  height: 20px;
  line-height: 17px;
  background: #fff;
  border: solid 1px rgba(80, 90, 94, 0.4);
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

/* ACTIVE STATE */
.ta-radio.active {
  color: #505a5e;
}

.ta-radio.active:before {
  content: "";
  background: #505a5e;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 1;
  display: block;
}

.ta-radio.active:after {
  border: solid 1px rgba(80, 90, 94, 0.7);
}

/* DISABLED STATE */
.ta-radio.disabled {
  color: rgba(80, 90, 94, 0.4);
}

.ta-radio.disabled:after {
  background-color: #f7f8f8;
  border-color: rgba(182, 186, 189, 0.3);
}

.ta-radio.active.disabled:before {
   background: #cacdce;
}

.ta-radio-field {
  position: absolute;
  top: 0;
  left: -9999px;
  z-index: 5;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
}

.ta-radio-wrapper.inline {
  display: inline-block;
  padding-right: 15px;
}

.ta-radio-wrapper.inline.only-inline {
  padding-right: 0;
}

.ta-radio .ta-hint-text {
  margin-top: 0;
}

/* HOVER STATE */
.ta-radio:not(.disabled):hover {
  color: #505a5e;
}

.ta-radio:not(.disabled):hover:after {
  border-color: #505a5e;
  background-color: #edeeee;
  box-shadow: 0 0 5px 0 rgba(80, 90, 94, 0.3);
}
