.ta-files-input .filepond--root {
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  margin: 0;
}

.ta-files-input .filepond--file-action-button {
  cursor: pointer;
}

.ta-files-input .filepond--drop-label {
  color: #505a5e;
}

.ta-files-input .filepond--drop-label label {
  color: #505a5e;
  width: 100%;
}

.ta-files-input .filepond--image-preview-wrapper,
.ta-files-input .filepond--item-panel,
.ta-files-input .filepond--panel-root {
  border-radius: 3px;
}

.ta-files-input .filepond--item-panel {
  background-color: #f4f3f3;
}

.ta-files-input .filepond--file-action-button:hover,
.ta-files-input .filepond--file-action-button:focus {
  box-shadow: none;
}

.ta-files-input .filepond--file {
  color: #505a5e;
}

.ta-files-input [data-filepond-item-state*='error'] .filepond--item-panel,
.ta-files-input [data-filepond-item-state*='invalid'] .filepond--item-panel {
  background-color: rgba(203, 59, 67, 0.1);
}

.ta-files-input [data-filepond-item-state*='error'] .filepond--file,
.ta-files-input [data-filepond-item-state*='invalid'] .filepond--file {
  color: #cb3b43;
}

.ta-files-input [data-filepond-item-state*='error'] .filepond--file-action-button,
.ta-files-input [data-filepond-item-state*='invalid'] .filepond--file-action-button {
  background-color: #cb3b43;
}

.ta-files-input .filepond--panel-bottom:before,
.ta-files-input .filepond--panel-top:after {
  display: none;
}

.ta-files-input .filepond--image-preview-overlay-success {
  color: rgba(118, 152, 81, 0.8);
}

.ta-files-input .filepond--image-preview-overlay-failure {
  color: rgba(255, 255, 255, 0.8);
}

.ta-files-input .filepond--panel-root {
  background-color: #fff;
  border: 1px dashed rgba(80, 90, 94, 0.3);
}

.ta-files-input .filepond--root .filepond--drop-label {
  min-height: 107px;
}

.ta-files-input .filepond--root :not(text) .ta-files-input__max-size {
  opacity: 0.7;
  font-size: 10px;
  color: #505a5e;
}

.ta-files-input .filepond--root :not(text) .ta-files-input__drop-or-browse {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.ta-files-input .filepond--root :not(text) .ta-files-input__label i {
  font-size: 20px;
}

.ta-files-input>.ta-files-input__drop-or-browse {
  color: #505a5e;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.ta-files-input__types {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
}

.ta-files-input .filepond--root :not(text) .ta-files-input__type {
  font-size: 9px;
}

.ta-files-input__type {
  border: 1px solid #505a5e;
  border-radius: 30px;
  padding: 0 4px;
  line-height: 1;
  font-weight: 500;
  color: #505a5e;
}

.ta-files-input__type:not(:last-of-type) {
  margin-right: 4px;
}

.ta-files-input [data-filepond-item-state='processing-complete'] .filepond--item-panel {
  background-color: #fff;
  border: 1px solid #cbced0;
}

.ta-files-input [data-filepond-item-state='processing-complete'] .filepond--file {
  color: #505a5e;
  box-shadow: none;
}

.ta-files-input [data-filepond-item-state='processing-complete'] .filepond--file-action-button {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

.ta-files-input [data-filepond-item-state='processing-complete'] .filepond--file-action-button svg {
  display: none;
}

.ta-files-input [data-filepond-item-state='processing-complete'] .filepond--file-action-button::after {
  content: "\f2ed";
  font-family: "Font Awesome 5 Pro";
  top: 20%;
  right: -40%;
  font-weight: 900;
  font-size: 12px;
  color: #505a5e;
}

.ta-files-input [data-filepond-item-state='processing-complete'] .filepond--action-revert-item-processing~.filepond--file-info .filepond--file-info-sub {
  font-size: 12px;
}

.ta-files-input [data-filepond-item-state='processing-complete'] .filepond--file-info {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  min-width: calc(100% - 28px);
  transform: translate3d(0px, 4px, 0px) !important;
  /* !important needed because the filepond plugin styles the same property in the HTML style attribute and it overwrites CSS written without !important */
  /* valid for all !important statements */
}

.ta-files-input .filepond--item {
  width: 100% !important;
  left: -5px;
}

.ta-files-input .filepond--item[data-filepond-item-state='load-invalid'] .filepond--file-status {
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ta-files-input.has-value .filepond--panel-root {
  background: transparent;
}

.ta-files-input .filepond--list .filepond--image-preview {
  opacity: 0 !important;
}

.ta-files-input .filepond--list .filepond--image-preview-overlay {
  opacity: 0 !important;
}

.ta-files-input .filepond--list .filepond--item {
  height: 38px !important;
}

.ta-files-input .filepond--list .filepond--item .filepond--panel-center {
  height: 24px !important;
  transform: translate3d(0px, 7px, 0px) scale3d(1, 1, 1) !important;
}

.ta-files-input .filepond--list .filepond--item .filepond--panel-bottom {
  transform: translate3d(0px, 31px, 0px) !important;
}

.ta-files-input.single-upload.has-value .filepond--root {
  height: 66px !important;
}

.ta-files-input.single-upload.has-value .filepond--root .filepond--list-scroller {
  height: 48px !important;
}

.ta-files-input.single-upload.has-value .filepond--root>.filepond--panel .filepond--panel-center {
  height: 55px !important;
  transform: translate3d(0px, 7px, 0px) scale3d(1, 1, 1) !important;
}

.ta-files-input.single-upload.has-value .filepond--root>.filepond--panel .filepond--panel-bottom {
  transform: translate3d(0px, 60px, 0px) !important;
}

.ta-files-input .ta-files-input__file {
  height: 38px;
  border: 1px solid #cbced0;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 8px 8px 7px;
  justify-content: space-between;
  width: 100%;
}

.ta-files-input .ta-files-input__file:not(:last-child) {
  margin-bottom: 7px;
}

.ta-files-input .ta-files-input__file-info {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 28px);
  margin-right: 5px;
}


.ta-files-input .ta-files-input__file .ta-btn {
  height: 23px;
  width: 23px;
  line-height: 23px;
  background-color: rgba(0, 0, 0, 0.3);
  border-width: 0;
  color: #505a5e;
}

.ta-files-input .ta-files-input__file>.ta-btn i::before {
  content: "\f2ed";
}

.ta-files-input .ta-files-input__file-info>.ta-btn {
  border: solid 1px #cbced0;
  background-color: transparent;
  color: #b6babd;
}

.ta-files-input .ta-files-input__supported-types {
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  opacity: 0.5;
  color: #505a5e;
  margin: 4px 0;
  font-style: italic;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ta-files-input .ta-files-input__files {
  transition: height .2s;
}

.ta-files-input .ta-files-input__file-name,
.ta-files-input [data-filepond-item-state='processing-complete'] .filepond--file-info-main,
.ta-files-input [data-filepond-item-state='processing-error'] .filepond--file-info-main,
.ta-files-input [data-filepond-item-state='load-invalid'] .filepond--file-info-main {
  font-size: 12px;
  color: #505a5e;
  font-weight: bold;
  font-weight: 900;
  line-height: 1.2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.ta-files-input .ta-files-input__file-size,
.ta-files-input [data-filepond-item-state=processing-complete] .filepond--action-revert-item-processing~.filepond--file-info .filepond--file-info-sub,
.ta-files-input [data-filepond-item-state='processing-error'] .filepond--file-info-sub,
.ta-files-input [data-filepond-item-state='load-invalid'] .filepond--file-info-sub {
  font-size: 12px;
  opacity: 0.6;
  color: #505a5e;
}

/* Error  */

.ta-files-input [data-filepond-item-state*='error'] .filepond--file,
.ta-files-input [data-filepond-item-state*='invalid'] .filepond--file {
  color: #505a5e;
}

.ta-files-input [data-filepond-item-state*='error'] .filepond--item-panel,
.ta-files-input [data-filepond-item-state*='invalid'] .filepond--item-panel {
  border: solid 1px #e59da1;
  background-color: #f9ebec;
}

.ta-files-input [data-filepond-item-state*=error] .filepond--file-info,
.ta-files-input [data-filepond-item-state*=invalid] .filepond--file-info,
.ta-files-input [data-filepond-item-state=cancelled] .filepond--file-info {
  flex-direction: row;
  transform: translate3d(0px, 5px, 0px) !important;
  min-width: calc(100% - 28px);
}

.ta-files-input [data-filepond-item-state*=error] .filepond--file-info {
  max-width: calc(100% - 56px);
  min-width: unset;
}

.ta-files-input [data-filepond-item-state*='error'] .filepond--file-action-button.filepond--action-remove-item,
.ta-files-input [data-filepond-item-state*='invalid'] .filepond--file-action-button.filepond--action-remove-item {
  right: 8px;
  left: auto;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

.ta-files-input [data-filepond-item-state*='error'] .filepond--file-action-button.filepond--action-remove-item>svg,
.ta-files-input [data-filepond-item-state*='invalid'] .filepond--file-action-button.filepond--action-remove-item>svg {
  display: none;
}

.ta-files-input [data-filepond-item-state*='error'] .filepond--file-action-button.filepond--action-remove-item::before,
.ta-files-input [data-filepond-item-state*='invalid'] .filepond--file-action-button.filepond--action-remove-item::before {
  content: "\f2ed";
  font-family: "Font Awesome 5 Pro";
  color: #505a5e;
  font-weight: 900;
}

.ta-files-input [data-filepond-item-state*='error'] .filepond--file-action-button.filepond--action-retry-item-processing,
.ta-files-input [data-filepond-item-state*='invalid'] .filepond--file-action-button.filepond--action-retry-item-processing {
  right: 35px;
  left: auto;
  border: solid 1px #cbced0;
  background-color: transparent;
  border-radius: 3px;
}

.ta-files-input [data-filepond-item-state*='error'] .filepond--file-action-button.filepond--action-retry-item-processing>svg,
.ta-files-input [data-filepond-item-state*='invalid'] .filepond--file-action-button.filepond--action-retry-item-processing>svg {
  display: none;
}

.ta-files-input [data-filepond-item-state*='error'] .filepond--file-action-button.filepond--action-retry-item-processing::before,
.ta-files-input [data-filepond-item-state*='invalid'] .filepond--file-action-button.filepond--action-retry-item-processing::before {
  content: "\f2f9";
  font-family: "Font Awesome 5 Pro";
  color: #b6babd;
  font-weight: 900;
}

.ta-files-input [data-filepond-item-state*='error'] .filepond--file-status,
.ta-files-input [data-filepond-item-state*='invalid'] .filepond--file-status {
  display: none;
}

.ta-files-input .ta-form-error span {
  word-break: break-word;
}

.ta-files-input .ta-form-control__addon {
  position: absolute;
  top: auto;
  bottom: 7px;
  right: 12px;
  color: #505a5e;
  font-size: 10px;
  font-weight: 500;
  opacity: 0.5;
}