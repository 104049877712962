.ta-input__limit {
  position: relative;
  bottom: 7px;
  right: 7px;
  color: #505a5e;
  font-size: 10px;
  font-weight: 500;
  opacity: 0.5;
  float: left;
}

.ta-input__right-margin {
  margin-right: 45px;
}
