.ta-calendar-day-input {
  position: relative;
  height: 30px;
  display: flex;
}

.ta-calendar-day-input__btn {
  background: #d3d5d6;
  height: 30px;
  line-height: 31px;
  width: 28px;
  text-align: center;
  cursor: pointer;
  color: #505a5e;
  font-size: 12px;
}

.ta-calendar-day-input__custom-input {
  width: 150px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f7f9;
  cursor: pointer;
  color: #505a5e;
  font-size: 11px;
}

.ta-calendar-day-input__custom-input.hasHoliday {
  color: #b26492;
}

.ta-calendar-day-input.open .ta-calendar-day-input__custom-input {
  height: 40px;
  border-radius: 3px 3px 0 0;
  border: 1px solid #fff;
  border-bottom: none;
  background: #d3d5d6;
  transform: translateY(-5px) translateZ(0);
}

.ta-calendar-day-input .ta-calendar-day-input__week-label {
  font-weight: 500;
  margin-left: 6px;
}

.ta-calendar-day-input-wrapper {
  width: 302px;
  position: absolute;
  top: 34px;
  left: 0;
  margin-left: -46px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
  padding: 1px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 3px;
}

.ta-calendar-day-input.open .ta-calendar-day-input-wrapper {
  box-shadow: 0px 0px 34px 4px rgba(0, 0, 0, 0.3);
}

.ta-calendar-day-input.open .ta-hover-popup {
  z-index: 50;
}

.ta-calendar-day-input .ta-calendar-day-input-datepicker {
  width: 100%;
}

.ta-calendar-day-input .ta-calendar-day-input__picker-container {
  display: flex;
  flex-wrap: wrap;
}

.ta-calendar-day-input .react-datepicker {
  background: none;
  border: none;
}

.ta-calendar-day-input .react-datepicker__header {
  padding-top: 0;
  border-bottom: none;
  background: none;
}

.ta-calendar-day-input .react-datepicker__day {
  height: 30px;
  line-height: 30px;
  margin: -1px 1px 0 1px;
  border: 1px solid #fff;
}

.ta-calendar-day-input .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 39px;
  margin: 0;
}

.ta-calendar-day-input .react-datepicker__day {
  color: inherit;
}

.ta-calendar-day-input .react-datepicker__day-name {
  font-size: 10px;
  color: rgba(80, 90, 94, 0.3);
  height: 26px;
  line-height: 32px;
}

.ta-calendar-day-input .react-datepicker__week {
  border: 1px solid rgba(0, 0, 0, 0);
}

.ta-calendar-day-input .react-datepicker__day-selected:hover {
  border: solid 1px #385f87;
  border-radius: 0;
  background-color: #385f87 !important;
}

.ta-calendar-day-input .react-datepicker__current-month {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  height: 44px;
  color: #505a5e;
  background: #d3d5d6;
  border-radius: 3px 3px 0 0;
}

.ta-calendar-day-input .react-datepicker__navigation--next, .ta-calendar-day-input .react-datepicker__navigation--previous {
  outline: none;
  width: 40px;
  height: 44px;
  border: none;
  color: #505a5e;
}

.ta-calendar-day-input .react-datepicker__navigation {
  top: 0;
}

.ta-calendar-day-input .react-datepicker__navigation--previous {
  left: 0;
}

.ta-calendar-day-input .react-datepicker__navigation--previous:before {
  content: "\f104";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  font-size: 15px;
  color: #505a5e;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-indent: 0;
}

.ta-calendar-day-input .react-datepicker__navigation--next {
  right: 0;
}

.ta-calendar-day-input .react-datepicker__navigation--next:before {
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  font-size: 15px;
  color: #505a5e;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-indent: 0;
}

.ta-calendar-day-input .react-datepicker__day:hover {
  border-radius: 3px;
  border: solid 1px #385f87;
}

.ta-calendar-day-input .react-datepicker__day--outside-month {
  color: rgba(80, 90, 94, 0.5);
  font-weight: 400;
}

.ta-calendar-day-input .react-datepicker__week {
  margin-top: 1px;
}

.ta-calendar-day-input .react-datepicker__day--selected {
  border-radius: 3px;
  border: solid 1px #385f87;
  background: #385f87;
  color: #fff;
}

.ta-calendar-day-input .react-datepicker__day-selected {
  border: solid 1px #385f87;
  background: #385f87;
  color: #fff;
}

.ta-calendar-day-input .react-datepicker__day-current {
  font-weight: bold;
  color: #cb3b43;
}
