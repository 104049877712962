.ta-section-title {
  position: relative;
  border-bottom: 1px solid #c9c8c6;
  font-size: 15px;
  font-weight: 400;
  color: #1c272b;
  padding: 4px 0;
  padding-left: 20px;
  margin: 10px 0;
}

.ta-section-title.ignore-top-offset {
  margin-top: 0;
  padding-top: 0;
}

.ta-section-title.no-icon {
  padding-left: 0;
}

.ta-section-title.ignore-top-offset .ta-section-title__icon {
  top: calc(50% - 3px);
}

.ta-section-title .ta-section-title__icon {
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 14px;
  color: #adbdc6;
  transform: translateY(-50%);
}

.ta-section-title .ta-section-title__extra-text {
  color: #a1a6a8;
  font-size: 12px;
  font-style: italic;
  margin-left: 5px;
  position: relative;
  top: 1px;
}

.ta-section-title__label {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  height: 20px;
  line-height: 20px;
  vertical-align: middle;
  font-weight: 500;
}

.ta-section-title__content {
  position: absolute;
  right: 0;
  bottom: 2px;
  font-size: 12px;
  line-height: 18px;
}

.ta-section-title__content .ta-btn {
  height: 25px;
  line-height: 23px;
}

.ta-section-title__content .ta-btn-icon-only {
  width: 25px;
}

.ta-section-title .ta-btn {
  margin-left: 3px;
}

.ta-section-title .ta-offline {
  margin-left: 3px;
}

.ta-section-title .ta-offline .ta-btn {
  margin-left: 0;
}

.ta-section-title__extra-label {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 5px;
  height: 18px;
  width: max-content;
}

.is-dragging .ta-section-title .ta-grab {
  border-color: #cb3b43;
  background: #cb3b43;
  color: #fff;
}
