.ta-textarea {
  position: relative;
  outline: none;
  background-color: transparent;
  min-height: 72px;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.ta-textarea::-webkit-scrollbar {
  display: none;
}

.ta-textarea__has-limit {
  min-height: 60px;
}

.ta-textarea__limit {
  position: absolute;
  bottom: 7px;
  right: 7px;
  color: #505a5e;
  font-size: 10px;
  font-weight: 500;
  opacity: 0.5;
  line-height: 1;
}

.ta-textarea__wrapper {
  min-height: 100px;
  padding-top: 18px;
}

.ta-textarea__has-resize-horizontally {
  resize: horizontal;
  overflow-x: auto;
}

.ta-textarea__has-resize-vertically {
  resize: vertical;
  overflow-y: auto;
}

.ta-textarea__has-resize-both {
  resize: both;
  overflow: auto;
}

.ta-textarea__wrapper .ta-form-control.ta-textarea:hover {
  box-shadow: none;
  background-color: none;
}

.ta-form-group.focused:not(.no-label) .ta-textarea__wrapper .ta-form-control,
.ta-form-group.filled:not(.no-label) .ta-textarea__wrapper .ta-form-control {
  padding-top: 0;
}
