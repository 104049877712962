.ta-calendar-month-input {
  position: relative;
  height: 30px;
  display: flex;
}

.ta-calendar-month-input .ta-calendar-month-input__btn {
  background: #d3d5d6;
  height: 30px;
  line-height: 31px;
  width: 28px;
  text-align: center;
  cursor: pointer;
  color: #505a5e;
  font-size: 12px;
}

.ta-calendar-month-input .ta-calendar-month-input__btn:first-child {
  border-radius: 3px 0 0 0;
}

.ta-calendar-month-input .ta-calendar-month-input__btn:last-child {
  border-radius: 0 3px 0 0;
}

.ta-calendar-month-input .ta-calendar-month-input__custom-input {
  width: 150px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f7f9;
  cursor: pointer;
  color: #505a5e;
  font-size: 11px;
}

.ta-calendar-month-input .ta-calendar-month-input__custom-input.disabled {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-left: -4px;
}

.ta-calendar-month-input.open .ta-calendar-month-input__custom-input {
  z-index: 40;
  height: 40px;
  border-radius: 3px 3px 0 0;
  border: 1px solid #fff;
  border-bottom: none;
  background: #d3d5d6;
  transform: translateY(-5px) translateZ(0);
}

.ta-calendar-month-input .ta-calendar-month-input__month-label {
  font-weight: 500;
  margin-left: 6px;
}

.ta-calendar-month-input .ta-calendar-month-input-wrapper {
  width: 288px;
  position: absolute;
  top: 34px;
  left: 0;
  margin-left: -36px;
  padding: 1px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 3px;
}

.ta-calendar-month-input .ta-calendar-month-input__months-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-self: center;
  justify-self: center;
  padding: 4px 8px;
}

.ta-calendar-month-input.open .ta-calendar-month-input-wrapper {
  box-shadow: 0px 0px 34px 4px rgba(0, 0, 0, 0.3);
}

.ta-calendar-month-input .ta-calendar-month-input-month {
  color: #505a5e;
  width: 85px;
  height: 32px;
  border: 1px solid none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin: 4px 0;
}

.ta-calendar-month-input .ta-calendar-month-input-month:hover {
  cursor: pointer;
  border: 1px solid #385f87;
  font-weight: bold;
  color: #385f87;
}

.ta-calendar-month-input .ta-calendar-month-input-month.active {
  cursor: pointer;
  background: #385f87;
  color: #fff;
  font-weight: bold;
}

.ta-calendar-month-input .ta-calendar-month-input__current-year {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  justify-items: center;
  width: 100%;
  height: 44px;
  color: #505a5e;
  background: #d3d5d6;
  border-radius: 3px 3px 0 0;
}

.ta-calendar-month-input .ta-calendar-month-input__current-year__prev, .ta-calendar-month-input .ta-calendar-month-input__current-year__next {
  width: 36px;
  height: 100%;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #505a5e;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

.ta-calendar-month-input .ta-calendar-month-input__current-year__prev:hover, .ta-calendar-month-input .ta-calendar-month-input__current-year__next:hover {
  cursor: pointer;
}

.ta-calendar-month-input .ta-calendar-month-input__current-year__label {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  color: #505a5e;
}
