.ta-booking-dependencies-input {
  position: relative;
}

.ta-booking-dependencies-input.disabled .ta-select__values__category {
  opacity: 0.5;
}

.ta-multiple-select__values-item-label {
  border-radius: 0;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 0px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 264px;
}
