.ta-simple-table {
  width: 100%;
  color: #505a5e;
}

.ta-simple-table__tr {
  display: table;
  width: calc(100% + 2px);
  border-spacing: 2px 1px;
  border-collapse: separate;
  margin: 0 -1px;
}

.ta-simple-table__cell {
  display: table-cell;
  background: #e4e6e8;
  font-size: 12px;
  font-weight: 300;
  padding: 7px 10px;
  margin: 1px;
  line-height: 16px;
  word-break: break-word;
}

.ta-simple-table__cell.th {
  background: #d6d8db;
}

.ta-simple-table__td {
  display: table-cell;
  background: #e4e6e8;
  font-size: 12px;
  font-weight: 300;
  padding: 7px 10px;
  margin: 1px;
  line-height: 16px;
  word-break: break-word;
}

.ta-simple-table__th {
  display: table-cell;
  background: #d6d8db;
  font-size: 12px;
  font-weight: 500;
  padding: 7px 10px;
  line-height: 16px;
  word-break: break-word;
}