.ta-accounts-switch {
  position: relative;
  display: inline-block;
  height: 42px;
  margin: 8px;
  border-radius: 3px;
  border: solid 1px #505a5e;
  background: #eff1f3;
  color: #505a5e;
  max-width: 168px;
  cursor: pointer;
}

.ta-accounts-switch:hover {
  border-color: #CB3B43;
  color: #CB3B43;
}

.ta-accounts-switch:hover .ta-navigation__toggle-arrow__icon__ghost{
  border-color: #CB3B43;
}

.ta-accounts-switch.active {
  background: #fff;
  color: #505a5e;
}

.ta-accounts-switch.disabled {
  pointer-events: none;
}

.ta-accounts-switch.no-cursor {
  cursor: default;
}

.ta-accounts-switch.no-cursor .ta-accounts-switch__arrow {
  display: none;
}

.ta-accounts-switch__label {
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
  padding: 0 10px 0 33px;
}

.ta-accounts-switch.active .ta-accounts-switch__arrow {
  display: none;
}

.ta-accounts-switch__arrow {
  position: absolute;
  left: 100%;
  top: 0;
}

.ta-accounts-switch__arrow__icon {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  font-weight: bold;
}

.ta-accounts-switch__icon {
  position: absolute;
  top: 50%;
  left: 11px;
  transform: translateY(-50%) translateZ(0);
  transition: left 0.2s;
  height: 20px;
  margin: 0 8px 3px 0;
  font-size: 18px;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
}

/* ICON ONLY MENU */

.ta-navigation.collapsed .ta-accounts-switch__label,
.ta-navigation.icon-only .ta-accounts-switch__label {
  font-size: 0px;
}

.ta-navigation.collapsed .ta-accounts-switch__icon,
.ta-navigation.icon-only .ta-accounts-switch__icon {
  left: 20px;
}

.ta-accounts-switch__company_name {
  margin: 0 0 2px 8px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ta-accounts-switch__subtitle{
  margin: 2px 39px 0 8px;
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
}

.ta-accounts-switch__name_wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 7px 0
}