.ta-booking-service-select {
  position: relative;
}

.ta-booking-service-select .ta-select__options__item__combination-icon {
  font-size: 11px;
  opacity: 0.7;
  margin-right: 4px;
}

.ta-booking-service-select__options {
  position: absolute;
  top: 37px;
  left: 0;
  right: 0;
  margin-top: 1px;
  box-shadow: none;
  border: 1px solid #385f87;
  border-radius: 0 0 3px 3px;
  background: #f6f7f9;
  padding: 5px;
  z-index: 100;
  background-color: rgb(235, 239, 243);
  box-shadow: 0 5px 10px 0 rgba(56, 95, 135, 0.15);
  max-height: 200px;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}

.ta-booking-service-select.active .ta-booking-service-select__options {
  visibility: visible;
  opacity: 1;
}

.ta-booking-service-select__options__item {
  border-bottom: 1px dashed #385f87;
  font-size: 13px;
  font-weight: 300;
  color: #969c9e;
  padding: 0 5px;
  height: 28px;
  line-height: 28px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
}

.ta-booking-service-select__options__item:before {
  content: "";
  border-top: 1px solid rgb(235, 239, 243);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.ta-booking-service-select__options__item:after {
  content: "";
  border-top: 1px solid rgb(235, 239, 243);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.ta-booking-service-select__options__item.selected, .ta-booking-service-select__options__item:hover {
  background-color: rgba(56, 95, 135, 0.1);
  color: #505a5e;
}

.ta-booking-service-select__options__item:last-child {
  border-bottom: none;
}

.ta-booking-service-select__options__new {
  font-size: 11px;
  font-style: italic;
  color: #385f87;
  line-height: 14px;
  padding: 5px;
  padding-right: 36px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  cursor: pointer;
}

.ta-booking-service-select__options__new__icon {
  position: absolute;
  right: 12px;
  top: 50%;
  font-size: 14px;
  width: 24px;
  border: 1px solid #385f87;
  text-align: center;
  border-radius: 3px;
  background: #dfe3e7;
  transform: translateY(-50%);
}

.ta-booking-service-select__options__new strong {
  margin-left: 4px;
  word-wrap: break-word;
}
