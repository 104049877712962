.ta-stripe-banner {
  color: #fff;
  background: #769851;
  height: 32px;
  display: flex;
  border-radius: 3px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
}

.ta-stripe-banner__message {
  font-size: 11px;
  font-weight: 500;
}

.ta-stripe-banner__icon {
  font-size: 30px;
  /* font-weight: 500; */
}