.ta-form.ta-2fa-form {
  padding: 0;
  display: flex;
  flex-direction: row;
}

.ta-2fa-form .ta-2fa-form__title {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #505a5e;
  align-items: center;
  margin-bottom: 8px;
}

.ta-2fa-form .ta-2fa-form__title .ta-2fa-form__title__circle {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #505a5e;
  margin-right: 6px;
}

.ta-2fa-form .ta-2fa-form__subtitle {
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #505a5e;
}

.ta-2fa-form .ta-2fa-form__qrcode {
  margin-bottom: 12px;
  margin-right: 12px;
}

.ta-2fa-form .ta-2fa-form__qrcode__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ta-2fa-form__copy-to-clipboard {
  padding-bottom: 8px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #505a5e;
  align-items: center;
  margin-bottom: 12px;
  position: relative;
}

.ta-2fa-form__copy-to-clipboard .ta-copy-to-clipboard__button.ta-btn {
  right: 0;
  height: 32px;
  width: 32px;
  padding: 0;
  background-color: #b6babd;
  border: transparent;
}

.ta-2fa-form__copy-to-clipboard .ta-copy-to-clipboard__button.ta-btn i {
  color: #505a5e;
}

.ta-2fa-form__copy-to-clipboard .ta-2fa-form__copy-to-clipboard__title {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #505a5e;
  max-width: 80px;
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ta-2fa-recovery-codes {
  background-color: rgba(118, 152, 81, 0.15);
  border-radius: 3px;
  padding: 18px 16px;
}

.ta-2fa-recovery-codes__title {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #769851;
  margin-bottom: 8px;
}

.ta-2fa-recovery-codes__success-icon {
  color: #769851;
  margin-right: 4px;
}

.ta-2fa-recovery-codes__description {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #505a5e;
  margin-bottom: 8px;
}

.ta-2fa-recovery-codes__list__item {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #505a5e;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.ta-2fa-recovery-codes__list__item__circle {
  height: 6px;
  width: 6px;
  display: inline-block;
  border-radius: 6px;
  background-color: #505a5e;
  margin-right: 8px;
}

.ta-2fa-login {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 9px 0 rgb(0 0 0 / 30%);
  border-radius: 3px;
  max-height: 80%;
}

.ta-2fa-login-header {
  padding: 18px 0;
  text-align: center;
  padding-left: 16px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #505a5e;
  background-color: #e7eaed;
  margin-bottom: 16px;
  border-radius: 3px 3px 0 0;
  position: relative;
}

.ta-2fa-login-content {
  padding: 0 16px 16px 16px;
}

.ta-form.ta-2fa-login-container {
  padding: 0;
  position: relative;
}

.ta-2fa-login-actions {
  margin-top: 16px;
}

.ta-2fa-login-actions button {
  width: 100%;
}

.ta-2fa-btn-full-width {
  width: 100%;
}

.ta-2fa-login-header .ta-2fa-select__box__logout {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 12px;
  z-index: 101;
  line-height: 16px;
  cursor: pointer;
}

.ta-2fa-login-header .ta-2fa-select__box__logout i {
  color: #505a5e;
}

.ta-2fa-company-message {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #505a5e;
  padding-bottom: 16px;
  border-bottom: 1px solid #cbced0;
  margin-bottom: 16px;
}

.ta-2fa-login .ta-2fa-btn-full-width {
  margin-top: 12px;
}

.ta-2fa-login-content {
  overflow: auto;
  max-height: calc(100vh - 260px);
}

.ta-2fa-form .ta-2fa-btn-full-width:disabled {
  background-color: #e2e4e5;
  color: #b6babd;
  border: none;
}

.ta-2fa-login-container .ta-2fa-activate-button:disabled {
  background-color: #e2e4e5;
  color: #b6babd;
  border: none;
}

.ta-2fa-form .ta-form-group input::placeholder {
  color: rgba(80, 90, 94, 0.6);
}

.ta-form.ta-2fa-disable-container {
  padding: 8px;
}

.ta-2fa-login__wrapper {
  padding-right: 288px;
  width: 780px;
  background-color: #f6f7f7;
}

.ta-2fa-form__login__wrapper {
  position: absolute;
  height: 100%;
  width: 288px;
  padding: 0 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  right: 0;
  top: 0;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
}

.ta-2fa-form__login__wrapper .ta-2fa-form__title {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #505a5e;
}

.ta-2fa-form__login__wrapper .ta-2fa-form__title .ta-2fa-form__title__circle {
  border: solid 1px #cbced0;
  height: 40px;
  width: 40px;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #b6babd;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-bottom: 8px;
}
