.ta-icon-select {
  position: relative
}

.ta-single-select .ta-form-group .Select-control {
  padding-right: 50px;
}

.ta-icon-select .ta-hover-popup {
  position: absolute;
  top: 5px;
  left: 0;
  z-index: 500;
  width: 50px;
  height: 32px;
  background: #fff;
  cursor: pointer;
}

.ta-icon-select__selected {
  width: 50px;
  height: 32px;
  border: 1px solid #505a5e;
  border-radius: 3px 0 0 3px;
}

.ta-icon-select__selected.disabled {
  opacity: 0.5;
  cursor: default;
  background-color: #e6e6e7;
}

.ta-icon-select__selected .ta-icon-select__selected-icon {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: #505a5e;
  padding-left: 10px;
}

.ta-icon-select__selected.disabled .ta-icon-select__options__icon {
  cursor: default;
}

.ta-icon-select__selected .ta-icon-select__options__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%) translateZ(0);
}

.ta-icon-select__selected i.fa-sort {
  position: absolute;
  top: 10px;
  right: 9px;
  font-size: 12px;
  color: #505a5e;
}

.ta-icon-select__options {
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 501;
  width: 200px;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  padding: 10px 5px 5px 10px;
  border-radius: 3px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
}

.ta-icon-select__options.active {
  visibility: visible;
  opacity: 1;
}

.ta-icon-select__options .ta-icon-select__options__icon {
  margin-right: 5px;
  margin-bottom: 5px;
  border: solid 1px #cbced0;
  border-radius: 50%;
  padding: 4px;
}

.ta-icon-select__options__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  background: #fff;
  cursor: pointer;
  position: relative;
  color: rgba(80, 90, 94, 0.7);
}

.ta-icon-select .ta-form-control {
  padding-right: 55px;
}

.ta-icon-select .ta-form-input-limit {
  margin-right: 48px;
}

.ta-icon-select .ta-form-input-with-limit {
  padding-right: 102px;
}
