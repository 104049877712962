.ta-agents__preview__details .ta-simple-table {
  text-align: center;
}

.ta-agents__preview__details__header {
  position: relative;
}

.ta-agents__preview__details__status {
  display: inline-block;
  padding: 0 5px;
  height: 12px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 10px;
  background: #b6babd;
  font-size: 8px;
  font-weight: 500;
  color: #505a5e;
  margin: 0 auto;
  position: relative;
  left: 4px;
}

.ta-agents__preview__details__status.active i{
  padding: 0 2px;
}

.ta-agents__preview__details__status.active {
  background: #769851;
  color: #fff;
}

.ta-agents__preview__details__status.has-online-warning {
  background: #ecd007;
  color: #505a5e;
}

.ta-agents__preview__details__label {
  border: 2px solid #fff;
  background: #ecd007;
  font-size: 13px;
  color: #505a5e;
  width: 30px;
  height: 30px;
  line-height: 26px;
  text-align: center;
  border-radius: 30px;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 10;
  transform: translateX(14px);
}

.ta-agents__preview__details__label.owner {
  background: #ecd007;
}

.ta-agents__preview__details__label.admin {
  background: #cb3b43;
  color: #fff;
}

.ta-agents__preview__details__label.custom {
  background: #385f87;
  color: #fff;
}

.ta-agents__preview__details .ta-user-preview__name {
  margin-top: -5px;
  margin-bottom: 0;
}

.ta-agents__preview__details .ta-user-preview .ta-avatar{
  margin-bottom: 8px;
}

.ta-agents__preview .ta-simple-tabs.scrollable-content .ta-simple-tabs__body-item {
  padding-bottom: 20px;
}

.ta-agents__preview .ta-simple-tabs__body-item.ta-simple-tabs-courses,
.ta-agents__preview .ta-simple-tabs__body-item.ta-simple-tabs-services {
  padding-top: 20px;
}

.ta-agents__preview .ta-simple-tabs__body-item.ta-simple-tabs-courses .ta-section-title:first-child,
.ta-agents__preview .ta-simple-tabs__body-item.ta-simple-tabs-services .ta-section-title:first-child {
  margin-top: 0;
}

.ta-agents__preview .ta-agents__preview__courses .ta-no-results,
.ta-agents__preview .ta-agents__preview__services .ta-no-results {
  margin-top: 15px;
}

.ta-agents__preview .ta-agents__preview__courses .ta-simple-list-item__title,
.ta-agents__preview .ta-agents__preview__services .ta-simple-list-item__title {
  font-weight: 500;
  margin-bottom: 0;
  position: relative;
  top: 1px;
}

.ta-agents__preview__details__color {
  position: static;
}

.ta-agents__preview__details__confirmed {
  display: inline-block;
  background: rgba(118, 152, 81, 0.15);
  color: #769851;
  border-radius: 3px;
  padding: 0 5px;
  font-size: 11px;
  font-weight: 500;
}

.ta-agents__preview__details__invitation {
  margin-top: 5px;
}

.ta-agents__preview__allowance-plan__wrapper {
  padding-bottom: 10px;
  position: relative;
}

.ta-agents__preview__allowance-plan__wrapper:after {
  content: "";
  display: block;
  border-left: 2px dotted #b6babd;
  height: 22px;
  position: absolute;
  left: 5px;
  bottom: -5px;
}

.ta-agents__preview__allowance-plan__wrapper:last-child:after {
  display: none;
}

.ta-agents__preview__allowance-plan__day {
  font-size: 12px;
  font-weight: 500;
  color: #505a5e;
  position: relative;
  padding: 5px 0;
  padding-left: 18px;
}

.ta-agents__preview__allowance-plan__day:before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  border: 1px solid #505a5e;
  background: #505a5e;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) translateZ(0);
}

.ta-agents__preview__allowance-plan__day.empty:before {
  background: #f4f3f3;
}

.ta-agents__preview__allowance-plan__time {
  font-size: 12px;
  font-weight: 300;
  color: #505a5e;
  padding: 4px 0;
  padding-left: 18px;
  position: relative;
}

.ta-agents__preview__allowance-plan__time:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #b6babd;
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%) translateZ(0);
}

.ta-agents__preview__allowance-plan__time:after {
  content: "";
  display: block;
  border-left: 2px dotted #b6babd;
  height: 10px;
  position: absolute;
  left: 5px;
  bottom: 15px;
}

.ta-agents__preview__details .ta-dotted-timeline {
  margin-bottom: 8px;
}

.ta-agents__preview__services .ta-simple-list-item {
  height: 32px;
  min-height: 32px;
  padding: 6px 8px;
}

.ta-agents__preview__services .ta-btn:hover {
  color: #fff;
  background: #cb3b43;
  border: none;
}

.ta-agents__preview__services .ta-btn-icon-only {
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.ta-agents__preview__services .ta-message.active {
  margin-bottom: 0;
}

.ta-agents__preview__details .ta-form {
  padding-bottom: 0;
}

.ta-agents__preview__details .ta-list {
  padding: 0;
}

.ta-agents__preview__details li {
  list-style-type: none;
  position: relative;
  padding-left: 12px;
}

.ta-agents__preview__details li:before {
  content: "\f111";
  font-family: "Font Awesome 5 Pro";
  margin-right: 6px;
  font-weight: bold;
  font-size: 6px;
  position: absolute;
  left: 0;
}
