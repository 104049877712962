.ta-content-message {
  background: #f5f5f4;
  color: #505a5e;
  padding: 20px;
  opacity: 0.9;
  display: none;
  flex-direction: row;
  justify-content: space-between;
}

.ta-content-message.warning {
  background: #ebb65d;
  color: #fff;
}

.ta-content-message.active {
  display: flex;
  transform: translate3d(0, 0, 0);
}

.ta-content-message-info-container {
  align-self: center;
}

.ta-content-message .ta-content-message-icon {
  float: left;
  margin-right: 8px;
  align-self: center;
}

.ta-content-message .ta-content-message-title {
  font-size: 18px;
  font-weight: 500;
  margin: 4px auto 12px auto;
}

.ta-content-message .ta-content-message-text {
  font-size: 13px;
  margin: 4px auto;
}

.ta-content-message .ta-content-message-close {
  width: 15px;
  height: 18px;
  font-size: 18px;
  font-weight: normal;
  cursor: pointer;
  justify-self: flex-end;
  align-self: center;
}

.ta-content-message .ta-content-message-buttons {
  align-self: center;
  justify-self: flex-end;
  margin-left: auto;
  margin-right: 40px;
}

.ta-content-message .ta-content-message-button {
  text-align: center;
  background: #cb3b43;
  padding: 12px 16px;
  color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  width: 220px;
}

.ta-content-message .ta-content-message-button-title {
  font-size: 15px;
  font-weight: bold;
  margin: 4px auto;
}

.ta-content-message .ta-content-message-button-text {
  font-size: 12px;
  font-weight: normal;
  margin: 4px auto;
}

.ta-content-message-text a {
  color: inherit;
  text-decoration: underline;
}
