.ta-sortable-list__item {
  position: relative;
  user-select: none;
}

.ta-sortable-list__item.dragging {
  margin-bottom: 8px;
}

.ta-sortable-list__item.dragging:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(56, 95, 135, 0.1);
  border: 1px dashed #385f87;
  border-radius: 3px;
}

.ta-sortable-list__item__handler {
  color: #b6babd;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}

.ta-sortable-list__item.dragging .ta-sortable-list__item__handler {
  opacity: 0;
}

.ta-sortable-list__item__handler__drag-source {
  width: 28px;
	height: 51px;
	border-radius: 3px;
  background-color: rgba(182, 186, 189, 0.5);
  margin: 4px;
  color: #505a5e;
}

.ta-sortable-list__item__handler__drag-source:hover {
  color: #fff;
  background: #cb3b43;
  cursor: move;
}

.ta-sortable-list__item__handler i {
  font-size: 17px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ta-sortable-list__item.empty .ta-sortable-list__item__handler {
  display: none;
}

.ta-sortable-list__preview {
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 470px;
  height: 100%;
}

.ta-sortable-list__preview > div {
  box-shadow: rgba(0, 0, 0, 0.5) 0 0 13px 0 ;
  opacity: 0.8;
  border-radius: 3px;
}
