.blur {
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
  -webkit-filter: url(#blur-filter);
  filter: url(#blur-filter);
  -webkit-filter: blur(3px) grayscale(100%);
  filter: blur(3px) grayscale(100%);
  opacity: 0.5;
  overflow: hidden;
}

.blur-svg {
  display: none;
}

.ta-blur .blur {
  min-height: 70px;
  padding: 5px;
}

.ta-blur {
  color: #505a5e;
  position: relative;
}

.ta-blur__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.ta-blur__content {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%) translateZ(0);
  text-align: center;
  display: none;
}

.ta-blur.active .ta-blur__content {
  display: block;
}

.ta-blur.active .ta-blur__overlay {
  z-index: 2;
}

.ta-blur__content a {
  color: #505a5e;
}

.ta-blur__content__icon {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  line-height: 35px;
  border: 1px solid #505a5e;
  display: inline-block;
  margin-bottom: 10px;
}

.ta-blur__content__activate-label, .ta-blur__content__label {
  font-size: 12px;
  font-weight: 500;
}

.ta-blur__content__activate-label {
  display: none;
}

/* .ta-blur.big .ta-blur__content__link {
  cursor: default;
} */

.ta-blur .ta-blur__content__link {
  display: inline-block;
  cursor: pointer;
}

.ta-blur .ta-blur__content__link:hover {
  color: #cb3b43;
}

.ta-blur .ta-blur__content__link:hover .ta-blur__content__label {
  display: none;
}

.ta-blur .ta-blur__content__link:hover .ta-blur__content__activate-label {
  display: block;
}

.ta-blur .ta-blur__content__link:hover .ta-blur__content__icon {
  color: #fff;
  background: #cb3b43;
  border-color: #cb3b43;
}

.ta-blur__content__button {
  margin-top: 15px;
}

.ta-blur__content__text {
  font-size: 13px;
  font-weight: 300;
  margin-top: 15px;
  line-height: 16px;
}

.ta-blur .ta-blur__content__link .ta-blur__content__icon .ta-blur__content__icon__active {
  display: none;
}

.ta-blur .ta-blur__content__link:hover .ta-blur__content__icon .ta-blur__content__icon__normal {
  display: none;
}

.ta-blur .ta-blur__content__link:hover .ta-blur__content__icon .ta-blur__content__icon__active {
  display: inline-block;
}
