.ta-loader-box {
  display: block;
  position: relative;
  min-height: 100px;
}

.ta-loader-box img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  border-radius: 3px;
}
