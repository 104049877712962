.ta-staging__indicator__top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: repeating-linear-gradient(
    -45deg,
    #F1B700,
    #F1B700 5px,
    #32291D 5px,
    #32291D 10px
  );
  z-index: 99999;
  opacity: 0.5;
}

.ta-staging__indicator__bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background: repeating-linear-gradient(
    -45deg,
    #F1B700,
    #F1B700 5px,
    #32291D 5px,
    #32291D 10px
  );
  z-index: 99999;
  opacity: 0.5;
}

.ta-staging__indicator__left {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 3px;
  background: repeating-linear-gradient(
    -45deg,
    #F1B700,
    #F1B700 5px,
    #32291D 5px,
    #32291D 10px
  );
  z-index: 99999;
  opacity: 0.5;
}

.ta-staging__indicator__right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 3px;
  background: repeating-linear-gradient(
    -45deg,
    #F1B700,
    #F1B700 5px,
    #32291D 5px,
    #32291D 10px
  );
  z-index: 99999;
  opacity: 0.5;
}