.ta-small-title {
  font-size: 14px;
  font-weight: 100;
  color: #505a5e;
  margin: 15px 0 10px 0;
}

.ta-small-title.bold {
  font-weight: 500;
}
