.ta-list {
  font-size: 12px;
  line-height: 20px;
  font-weight: 300;
  color: #505a5e;
  padding: 3px 0;
}

.ta-list.bold {
  font-weight: 700;
}

.ta-list.bullet-item {
  line-height: 1;
  padding: 4px 0;
  word-break: break-all;
}

.ta-list.bullet-item:before {
  content: "\f111";
  font-family: "Font Awesome 5 Pro";
  color: #b6babd;
  margin-right: 6px;
  font-weight: bold;
  font-size: 6px;
  position: relative;
  left: 0;
  top: -2px;
}

.ta-list.bold.bullet-item:before {
  color: #505A5E;
}

.ta-list.bullet-item > span {
  display: inline-block; 
}

.ta-list.bullet-item.black {
  color: #505a5e;
  font-weight: 900;
}

.ta-list.bullet-item.black:before {
  color: #505a5e;
  font-weight: bold;
}

.ta-list a {
  color: #CB3B43;
  text-decoration: underline;
}

.ta-list.blue-link a {
  color: #385f87;
}

.ta-list a:hover {
  text-decoration: none;
}

.ta-list .ta-popup__trigger a {
  color: #505a5e;
}