.ta-simple-list-item {
  background: #fff;
  color: #505a5e;
  border-radius: 3px;
  border: 1px solid #fff;
  margin-bottom: 8px;
  padding: 10px;
  position: relative;
  min-height: 40px;
  cursor: pointer;
}

.ta-simple-list-item.no-pointer {
  cursor: default;
}

.ta-simple-list-item.draggable {
  cursor: grab;
}

.ta-sortable-list__item.dragging .ta-simple-list-item {
  opacity: 0;
  margin-bottom: 0;
}

.ta-simple-list-item:after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  width: 5px;
  border-radius: 3px 0 0 3px;
}

.ta-simple-list-item:not(.no-hover):hover:after {
  width: 4px;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 2px 0 0 2px;
}

.ta-simple-list-item:not(.no-hover):hover {
  background: #f6f8fa;
  border-color: #505a5e;
}

.ta-simple-list-item.course-booking,
.ta-simple-list-item.single-booking,
.ta-simple-list-item.title-booking,
.ta-simple-list-item.stalled,
.ta-simple-list-item.green,
.ta-simple-list-item.light-green,
.ta-simple-list-item.aqua,
.ta-simple-list-item.light-blue,
.ta-simple-list-item.purple,
.ta-simple-list-item.red,
.ta-simple-list-item.course,
.ta-simple-list-item.violet,
.ta-simple-list-item.dusk,
.ta-simple-list-item.grey,
.ta-simple-list-item.breaks,
.ta-simple-list-item.ocher,
.ta-simple-list-item.orange,
.ta-simple-list-item.brown,
.ta-simple-list-item.wood,
.ta-simple-list-item.default,
.ta-simple-list-item.cancelled,
.ta-simple-list-item.inactive,
.ta-simple-list-item.active {
  padding-left: 15px;
}

.ta-simple-list-item.course-booking:after {
  background: #b26492;
}

.ta-simple-list-item.single-booking:after {
  background: #385f87;
}

.ta-simple-list-item.title-booking:after {
  background: #ebb65d;
}

.ta-simple-list-item.stalled:after {
  background: #ecd007;
}

.ta-simple-list-item.green:after {
  background: #82A774;
}

.ta-simple-list-item.light-green:after {
  background: #95C5A6;
}

.ta-simple-list-item.aqua:after {
  background: #8EBEC3;
}

.ta-simple-list-item.light-blue:after {
  background: #6882B0;
}

.ta-simple-list-item.purple:after {
  background: #7684CA;
}

.ta-simple-list-item.red:after {
  background: #B22E53;
}

.ta-simple-list-item.course:after {
  background: #B26492;
}

.ta-simple-list-item.violet:after {
  background: #A476CA;
}

.ta-simple-list-item.dusk:after {
  background: #5D5A79;
}

.ta-simple-list-item.grey:after {
  background: #8B8B8B;
}

.ta-simple-list-item.breaks:after {
  background: #EBB65D;
}

.ta-simple-list-item.ocher:after {
  background: #CC9E52;
}

.ta-simple-list-item.orange:after {
  background: #E5774C;
}

.ta-simple-list-item.brown:after {
  background: #C46F4E;
}

.ta-simple-list-item.wood:after {
  background: #7B6161;
}

.ta-simple-list-item.default:after {
  background: #385f87;
}

.ta-simple-list-item.cancelled:after {
  background: #cb3b43;
}

.ta-simple-list-item.inactive:after {
  background: rgba(182, 186, 189, 0.5);
}

.ta-simple-list-item.active:after {
  background: #769851;
}

.ta-simple-list-item.booker-customer:after {
  background: #385f87;
}

.ta-simple-list-item.normal-customer:after {
  background: #f79a83;
}

.ta-simple-list-item__title {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 2px;
  position: relative;
}

.ta-simple-list-item__title-text {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.ta-booking-form__success_list_item .ta-simple-list-item__title-text {
  font-size: 12px;
  font-weight: 500;
  color: #505a5e;
}

.ta-booking-form__success_list_item .ta-list-inline {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.ta-booking-form__success_list_item .ta-list-inline i {
  font-size: 12px;
  color: #505a5e;
}

.ta-booking-form__success_list_item .ta-list-inline li {
  font-size: 12px;
  color: #505a5e;
}

.ta-simple-list-item__title-text.has-icon {
  max-width: calc(100% - 20px);
}

.ta-simple-list-item__title-icon {
  margin-left: 8px;
  display: inline-block;
  max-width: 100px;
  position: relative;
  top: -1px;
  vertical-align: top;
}

.ta-simple-list-item__content {
  line-height: 17px;
  font-size: 12px;
  font-weight: 300;
  color: #737B7E;
}

.ta-simple-list-item__content i {
  font-size: 10px;
  position: relative;
  margin-right: 3px;
}

.ta-simple-list-item__content a {
  color: #cb3b43;
  text-decoration: underline;
}

.ta-simple-list-item__content a:hover {
  text-decoration: none;
}

.ta-simple-list-item__buttons {
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%) translateZ(0);
}

.ta-simple-list-item__heading {
  background: #e5e7e7;
  border-top: 1px solid #e5e7e7;
  border-right: 1px solid #e5e7e7;
  margin: -11px;
  margin-bottom: 10px;
  padding: 0 8px;
  height: 24px;
  line-height: 24px;
  border-radius: 0 3px 0 0;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  color: rgba(80, 90, 94, 0.7);
}

.ta-simple-list-item:hover .ta-simple-list-item__heading {
  border-color: #505a5e;
}
