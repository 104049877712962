.maps__wrapper.overlay{
  filter: blur(4px);
}

.maps__wrapper{
  height: 100%;
  width: 100%;
  transition: all 0.8s;
}

.maps__wrapper.overlay:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: all 0.8s;
  background-image: linear-gradient(to top, #1d3247, #385f87);
  opacity: .4;
}