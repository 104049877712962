.ta-user-preview {
  display: flex;
}

.ta-user-preview__name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
  text-align: left;
}

.ta-user-preview__name div{ 
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 1.33;
  color: #505a5e;
  max-width: 330px;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.ta-user-preview__name_last_login {
  opacity: 0.5;
  font-size: 12px;
  line-height: 1.33;
  color: #505a5e;
}

.ta-user-preview__name_content {
  font-size: 14px;
  font-weight: 500;
  color: #505a5e;
}