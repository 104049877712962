.ta-form-text {
  color: #505a5e;
  font-size: 13px;
  line-height: 18px;
  padding-bottom: 4px;
}

.ta-form-text.bold {
  font-weight: 600;
}

.ta-form-text a {
  color: #505a5e;
  font-weight: 500;
  text-decoration: underline;
}