.ta-small-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: -moz-linear-gradient(top, #385f87 0%, #1d3247 100%);
  background: -webkit-linear-gradient(top, #385f87 0%, #1d3247 100%);
  background: linear-gradient(to bottom, #385f87 0%, #1d3247 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( endColorstr='#1d3247', startColorstr='#385f87',GradientType=0 );
  visibility: hidden;
  opacity: 0;
  transition: all 0.8s;
}

.ta-small-screen-overlay.active {
  visibility: visible;
  opacity: 1;
}

.ta-small-screen-overlay__message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  line-height: 20px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
}

.ta-small-screen-overlay__message__head {
  font-size: 18px;
  margin-bottom: 16px;
}

.ta-small-screen-overlay__message__head i {
  font-size: 40px;
  margin-bottom: 16px;
}

.ta-small-screen-overlay__message__head strong {
  display: block;
  font-size: 25px;
  margin-bottom: 8px;
}

.ta-small-screen-overlay .ta-blue-template .ta-logo {
  left: 20px;
}

.ta-small-screen-overlay__message__body {
  margin-bottom: 16px;
}

.ta-small-screen-overlay__message__links a {
  margin: 0 3px;
}

.ta-small-screen-overlay__message__links img {
  height: 35px;
}

.ta-small-screen-overlay__message .ta-spinner {
  color: #fff;
  position: static;
  top: 0;
  left: 0;
  transform: none;
  font-size: 30px;
  margin-bottom: 20px;
}
