.ta-avatar {
  position: relative;
  width: 86px;
  height: 86px;
  line-height: 86px;
  text-align: center;
  border-radius: 86px;
  background: #eee;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  display: inline-block;
  margin-bottom: 10px;
  /* overflow: hidden; */
}

.ta-avatar__image {
  width: 86px;
  height: 86px;
}

.ta-avatar__image img {
  width: 86px;
  height: 86px;
  border-radius: 50%;
}

.ta-avatar__initials {
  background: url('../../Assets/images/avatar-overlay.svg') 0 0 no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
}
