.ta-menu {
  width: 100%;
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  font-size: 11px;
  font-weight: 500;
}

.ta-menu > ul > li {
  margin: 8px;
}

.ta-menu > ul > li > .ta-offline > .ta-offline-body > a,
.ta-menu > ul > li > a {
  display: flex;
  color: #385f87;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  font-size: 11px;
  line-height: 1.09;
  padding: 8px;
  border-radius: 3px;
  border: solid 1px #385f87;
  background-color: #eff1f3;
}

.ta-menu__item.ta-navigation__links__active__persist a{
  background-color: #cb3b43;
  border-color: #cb3b43;
  color: #fff;
}

.ta-menu > ul > li > a:hover {
  color: #cb3b43;
  border-color: #fff;
  background-color: #fff;
}

.ta-menu > ul > li > a span {
  margin-left: 8px;
  display: block
}

.ta-menu > ul > li > .ta-offline > .ta-offline-body > a .ta-menu__icon,
.ta-menu > ul > li > a .ta-menu__icon {
  font-size: 18px;
  width: 20px;
  height: 20px;
  line-height: 1;
  text-align: center;
  transition: left 0.2s;
}

.icons-group i {
  margin: 0 1px;
}

.ta-menu > ul > li .ta-offline {
  width: 100%;
  background: #25405a;
}

.ta-menu > ul > li .ta-offline-body {
  width: 100%;
}

.ta-menu > ul > li.active {
  background: #385f87;
}

.ta-menu > ul > li.active.no-childrens {
  background: #f4f3f3;
}

.ta-menu > ul > li.active > a {
  color: #fff;
}

.ta-menu > ul > li.active.no-childrens > a {
  background-color: #fff;
  border-color: #fff;
  color: #cb3b43;
}

.ta-menu > ul > li .ta-menu__item__sub-menu ul {
  background: #1d3247;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
}

.ta-menu > ul > li.active ul {
  max-height: 200px;
}

.ta-menu > ul > li .ta-menu__item__sub-menu ul > li {
  border-bottom: 1px solid #1d3247;
}

.ta-menu > ul > li .ta-menu__item__sub-menu ul > li.active {
  background: #f4f3f3;
}

.ta-menu > ul > li .ta-menu__item__sub-menu ul > li a {
  display: block;
  color: #fff;
  line-height: 11px;
  padding: 9px 0;
  padding-left: 11px;
  font-size: 10px;
  cursor: pointer;
}

.ta-menu > ul > li .ta-menu__item__sub-menu ul > li a:hover {
  color: #cb3b43;
}

.ta-menu > ul > li .ta-menu__item__sub-menu ul > li.active a {
  color: #cb3b43;
  position: relative;
}

.ta-menu > ul > li .ta-menu__item__sub-menu ul > li.active a:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) translateZ(0);
  width: 4px;
  height: 20px;
  background: #cb3b43;
  border-radius: 0 5px 5px 0;
}

.ta-menu__item__sub-menu a {
  display: none;
}

.ta-menu__avatar {
  position: absolute;
  left: 7px;
  top: 50%;
  transform: translateY(-50%) translateZ(0);
  width: 20px;
  height: 20px;
}

.ta-menu__avatar .ta-avatar {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 7px;
}

.ta-menu__avatar .ta-avatar .ta-avatar__image {
  width: 20px;
  height: 20px;
}

.ta-menu__avatar .ta-avatar .ta-avatar__image img {
  width: 20px;
  height: 20px;
}

.ta-menu__notifications {
  position: absolute;
  width: 12px;
  height: 38px;
  top: 0;
  left: 100%;
}

.ta-menu__notifications__counter {
  width: 16px;
  height: 16px;
  line-height: 17px;
  text-align: center;
  color: #fff;
  background: #cb3b43;
  position: absolute;
  top: 50%;
  left: -8px;
  transform: translateY(-50%) translateZ(0);
  border-radius: 16px;
  font-size: 8px;
  font-weight: 500;
}

.ta-menu__item__sub-menu__label {
  display: none;
}

/* ICON ONLY */

.ta-navigation.icon-only .ta-menu {
  overflow: visible;
}

.ta-navigation.icon-only .ta-menu > ul > li {
  position: relative;
}

.ta-navigation.collapsed .ta-menu > ul > li > a,
.ta-navigation.icon-only .ta-menu > ul > li > a {
  height: 37px;
}

.ta-navigation.collapsed .ta-menu > ul > li > a span,
.ta-navigation.icon-only .ta-menu > ul > li > a span {
  display: none;
}

.ta-navigation.icon-only .ta-menu > ul > li .ta-menu__item__sub-menu {
  display: none;
}

.ta-navigation.icon-only .ta-menu > ul > li .ta-menu__item__sub-menu ul {
  border-top: 1px solid #c9c8c6;
  max-height: 500px;
  min-width: 200px;
  background: #fff;
}


.ta-navigation.icon-only .ta-menu > ul > li .ta-menu__item__sub-menu ul > li {
  border-bottom: none;
  margin: 4px;
}

.ta-navigation.icon-only .ta-menu > ul > li .ta-menu__item__sub-menu ul > li a {
  color: #505a5e;
  padding: 10px 8px;
}

.ta-navigation.icon-only .ta-menu > ul > li .ta-menu__item__sub-menu ul > li.active a {
  color: #cb3b43;
  font-weight: 500;
}

.ta-navigation.icon-only .ta-menu > ul > li .ta-menu__item__sub-menu ul > li a:hover {
  background: #f4f3f3;
}

.ta-navigation.icon-only .ta-menu > ul > li:hover .ta-menu__item__sub-menu {
  display: block;
}

.ta-navigation.collapsed .ta-menu__icon,
.ta-navigation.icon-only .ta-menu__icon {
  left: 17px;
}

.ta-navigation.icon-only .ta-menu__item__sub-menu {
  position: absolute;
  left: 59px;
  top: 0;
  box-shadow: rgba(0,0,0, 0.2) 2px 2px 6px 0;
  border-radius: 0 3px 3px 0;
  overflow: hidden;
  border-left: 1px solid #1d3247;
}

.ta-navigation.icon-only .ta-menu__item__sub-menu__label {
  display: block;
  font-weight: 500;
  font-size: 13px;
  color: #505a5e;
  padding: 12px 10px;
  background: #fff;
}