.ta-google-static-map__img {
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
}

.ta-infowindow__title {
  height: 16px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  color: #505a5e;
}
.ta-infowindow__desc {
  height: 16px;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.33;
  color: #505a5e;
}

.ta-infowindow__container {
  padding: 0 10px;
}


.ta-cluster-icon {
  background: #cb3b43;
  color: #fff;
  border-radius: 100%;
  font-weight: bold;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.ta-cluster-icon__sm span {
  font-size: 12px;
  vertical-align: middle;
  line-height: 2;
}