.ta-navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 184px;
  height: 100%;
  min-height: 550px;
  background: #eff1f3;
  border-top: 3px solid #CB3B43;
  color: #fff;
  z-index: 1001;
  transform: translateX(-182px);
  transition: all 0.5s;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.25);
}

.ta-navigation.pending:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.ta-navigation .ta-offline .ta-offline-body,
.ta-navigation .ta-offline {
  width: 100%;
}

.ta-navigation.negative-1 {
  left: 440px;
  transition: all 0.5s;
}

.ta-navigation.hover {
  transform: translateX(-113px);
  box-shadow: rgba(0,0,0,0.5) 0 0 10px;
}

.ta-navigation.icon-only {
  transform: translateX(0);
  width: 60px;
}

.ta-navigation.expanded {
  transform: translateX(0);
}

.ta-navigation__toggle-arrow {
  position: absolute;
  top: 19px;
  left: 100%;
  z-index: 10;
  cursor: pointer;
}

/* IE FIX */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ta-navigation__toggle-arrow svg {
    display: none;
  }
}

.ta-navigation__toggle-arrow.disabled {
  display: none;
}

.ta-navigation__toggle-arrow__icon, .ta-navigation__toggle-arrow__icon__ghost {
  display: block;
  background: #3E5062;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  border-radius: 16px;
  position: absolute;
  font-size: 11px;
  top: 11px;
  left: -9px;
}

.ta-navigation__toggle-arrow__icon__ghost {
  right: -7px;
  top: 13px;
  width: 13px;
  height: 13px;
  left: initial;
  background-color: #eff1f3;
  border: solid 1px #505a5e;
}

.ta-navigation__toggle-arrow__icon__ghost i{
  position: absolute;
  left: 3px;
}

.ta-navigation__toggle-arrow__icon__ghost i.far.fa-angle-right {
  left: 4px;
}

.ta-navigation.expanded .ta-navigation__toggle-arrow:hover .ta-navigation__toggle-arrow__icon {
  background: #CB3B43;
}

.ta-navigation__toggle-arrow.collapsed .ta-navigation__toggle-arrow__icon {
  background: #CB3B43;
}

.ta-navigation__toggle-arrow.collapsed .ta-navigation__toggle-arrow__icon i {
  transform: translateX(2px);
}

.ta-navigation__expand {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 20px;
  z-index: 99999;
  cursor: pointer;
  transition: all 0.5s;
  transform: translateX(-20px);
}

.ta-navigation__expand.active {
  transform: translateX(0);
}

.ta-navigation__account {
  height: 77px;
  display: block;
  color: #fff;
  background: #CB3B43;
  margin-bottom: 2px;
  position: relative;
  cursor: pointer;
}

.ta-navigation__account .ta-avatar {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%) translateZ(0);
}

.ta-navigation__account .ta-avatar .ta-avatar__image img,
.ta-navigation__account .ta-avatar .ta-avatar__image {
  width: 100%;
  height: 100%;
}

.ta-navigation__account__avatar-icon {
  border: 1px solid #3E5062;
  background: #fff;
  color: #3E5062;
  font-size: 6px;
  width: 14px;
  height: 14px;
  line-height: 12px;
  border-radius: 12px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 32px;
  transform: translateY(-50%) translateZ(0);
  z-index: 2;
}

.ta-navigation__account__name {
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  line-height: 12px;
  position: absolute;
  top: 50%;
  left: 52px;
  right: 10px;
  transform: translateY(-50%) translateZ(0);
  word-wrap: break-word;
  max-height: 50px;
  max-width: 85px;
  overflow: hidden;
}

.ta-navigation__account:hover .ta-navigation__account__name {
  color: #CB3B43;
}

.ta-navigation.expanded .ta-navigation__account.active .ta-navigation__account__name {
  color: #CB3B43;
}

.ta-navigation.icon-only .ta-navigation__account,
.ta-navigation.expanded .ta-navigation__account {
  background: #3E5062;
}

.ta-navigation.expanded .ta-navigation__account.active {
  background: #fff;
}

.ta-navigation__plan {
  position: absolute;
  bottom: 88px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
}

.ta-navigation.hide-online-status .ta-navigation__plan {
  bottom: 50px;
}

.ta-navigation__links {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  background: #1d3247;
  text-align: center;
}

.ta-navigation__links.active {
  background: #fff;
}

.ta-navigation__links.active .fas.fa-sign-out-alt{
  color: #505a5e;
}

.ta-navigation.hide-online-status .ta-navigation__links {
  bottom: 5px;
}

.ta-navigation__links__link {
  display: inline-block;
  width: 40px;
  height: 100%;
}

.ta-navigation__links__link .ta-hover-popup__trigger,
.ta-navigation__links__link .ta-hover-popup__trigger a {
  height: 100%;
  line-height: 3.5;
}

.ta-navigation__links__link a {
  color: #fff;
  opacity: 0.35;
  font-size: 16px;
  display: block;
  padding: 10px;
  transition: all 0.5s;
  cursor: pointer;
}

.ta-navigation__links__link a:hover {
  opacity: 1;
}

/* ICON ONLY MENU */

.ta-navigation.collapsed .ta-navigation__account__name,
.ta-navigation.icon-only .ta-navigation__account__name {
  display: none;
}

.ta-navigation.collapsed .ta-navigation__plan,
.ta-navigation.icon-only .ta-navigation__plan {
  bottom: 125px;
}

.ta-navigation.collapsed .ta-navigation__plan .ta-plan-badge,
.ta-navigation.icon-only .ta-navigation__plan .ta-plan-badge {
  width: 100%;
  border-radius: 0;
}
.ta-navigation__links__wrapper {
  position: relative;
}

.ta-navigation__top__intro span:last-child{
  width: 184px;
  height: 24px;
  margin: 4px 0 0;
  padding: 4px 58px 4px 59px;
  background-color: #ecd007;
  width: 67px;
  height: 16px;
  font-family: Ubuntu;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #505a5e;
}

.ta-navigation__top__intro div:first-child{
  width: 184px;
  height: 4px;
  background-color: #cb3b43;
}
