.ta-dependencies-input {
  padding-left: 8px;
}

.ta-dependencies-input.one {
  padding-left: 0;
}

.ta-dependencies-input__group {
  position: relative;
}

.ta-dependencies-input__group:after {
  content: "";
  display: block;
  height: calc(100% + 24px);
  width: 8px;
  border: 1px solid rgba(80, 90, 94, 0.6);
  border-right: none;
  position: absolute;
  top: 16px;
  left: -8px;
  border-radius: 2px 0 0 2px;
}

.ta-dependencies-input__group:last-child:after {
  display: none;
}

.ta-dependencies-input__group .ta-form-error {
  transform: translateY(-5px) translateZ(0);
}

.ta-dependencies-input__select-container {
  border-radius: 3px;
  position: relative;
  padding-left: 138px;
  margin-bottom: 10px;
  margin-right: 30px;
}

.ta-dependencies-input.one .ta-dependencies-input__select-container {
  margin-right: 0;
}

.ta-dependencies-input__group .ta-bordered-box .ta-dependencies-input__select-container {
  margin-bottom: 0;
  padding-left: 127px;
  margin-right: 0;
}

.ta-dependencies-input__group .ta-bordered-box .ta-form-text {
  margin-top: 8px;
}

.ta-dependencies-input__select-container .ta-select {
  border-radius: 0 3px 3px 0;
}

.ta-dependencies-input__select-container .ta-select .ta-multi-select__values-item-label {
  max-width: 185px;
}

.ta-dependencies-input__select-container__label {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 140px;
  background: #e6e6e7;
  border-radius: 2px 0 0 2px;
  border: solid 1px rgba(80, 90, 94, 0.6);
  border-right: none;
  cursor: default;
}

.ta-dependencies-input__select-container__label span {
  display: inline-block;
  font-size: 12px;
  font-weight: 300;
  color: #505a5e;
  padding: 9px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.ta-dependencies-input__buttons {
  text-align: center;
  margin-bottom: 25px;
}

.ta-dependencies-input__buttons .ta-btn {
  height: 32px;
  line-height: 30px;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
}

.ta-dependencies-input__buttons__btn-delete {
  cursor: pointer;
  width: 24px;
  height: 32px;
  line-height: 32px;
  background: #b6babd;
  color: #505a5e;
  border-radius: 3px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 11px;
}

.ta-dependencies-input.one .ta-dependencies-input__buttons__btn-delete {
  display: none;
}

.ta-dependencies-input .ta-select {
  height: 32px;
  border: 1px solid rgba(80, 90, 94, 0.6);
  outline: none;
  box-shadow: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 2px;
}

.ta-dependencies-input .ta-select .ta-select-input {
  display: inline-block;
  padding: 7px 15px 7px 10px;
  line-height: 30px;
  border: 0;
  height: 30px;
  width: 100%;
}

.ta-dependencies-input .ta-single-select.hasValue:not(.active) {
  border: 1px solid rgba(80, 90, 94, 0.6);
  outline: none;
  box-shadow: none;
}

.ta-dependencies-input .ta-select.active {
  border-bottom-right-radius: 0;
}

.ta-dependencies-input .ta-select .ta-select__arrow {
  top: 11px;
}

.ta-dependencies-input .ta-select .ta-select__options.bottom {
  width: calc(100% - 140px);
  margin-left: 140px;
  top: 100%;
}

.ta-dependencies-input .ta-bordered-box .ta-select {
  margin-left: 13px;
}
