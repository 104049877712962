.ta-oauth {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1d3247 url('../../Assets/images/ta-background.jpg') 0 0 no-repeat;
  background-size: cover;
}

.ta-oauth__box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
}

.ta-oauth__box .ta-form {
  padding: 0;
}

.ta-oauth__box-content {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.3);
  padding: 20px;
  position: relative;
  z-index: 2;
}

.ta-oauth__box-content .ta-loader {
  bottom: 50px;
  border-radius: 3px;
}

.ta-oauth__title {
  margin: -18px -18px 20px -18px;
  height: 60px;
  line-height: 60px;
  background: #e4e6e8;
  font-size: 16px;
  font-weight: 500;
  color: #505a5e;
  border-radius: 3px 3px 0 0;
  text-align: center;
}

.ta-oauth__box-content__scrollable {
  max-height: 500px;
  overflow: auto;
  margin: -20px;
  padding: 20px;
}

.ta-oauth__box-footer {
  position: relative;
  z-index: 1;
  background: #efefef;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.3);
  border-radius: 0 0 3px 3px;
  margin: 0 20px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.ta-oauth__box-footer a {
  font-size: 12px;
  font-weight: 500;
  color: #505a5e;
  display: block;
}

.ta-oauth__box-footer a:hover {
  text-decoration: underline;
}

.ta-oauth__app__title {
  text-align: center;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 500;
  color: #505a5e;
}

.ta-oauth__box__logout.ta-hover-popup {
  position: absolute;
  top: 1px;
  right: 1px;
  z-index: 101;
  line-height: 16px;
}

.ta-oauth__box__logout.ta-hover-popup a {
  display: block;
  color: #505a5e;
  width: 50px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 16px;
}

.ta-oauth__app__logos {
  text-align: center;
  margin-bottom: 12px;
}

.ta-oauth__app__logos img {
  width: 100px;
  height: 50px;
}

.ta-oauth__app__logos__timify {
  width: 100px;
  height: 50px;
  border-radius: 3px;
  text-align: center;
  display: inline-block;
  background: #cb3b43;
  position: relative;
}

.ta-oauth__app__logos__timify img {
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ta-oauth__app__logos__separator {
  display: inline-block;
  width: 60px;
  border-bottom: 1px dashed rgba(80, 90, 94, 0.5);
  vertical-align: top;
  transform: translateY(25px);
  margin: 0 20px;
  position: relative;
}

.ta-oauth__app__logos__separator:before {
  content: "";
  border: 6px solid transparent;
  border-right-color: rgba(80, 90, 94, 0.5);
  position: absolute;
  top: -6px;
  right: 100%;
}

.ta-oauth__app__logos__separator:after {
  content: "";
  border: 6px solid transparent;
  border-left-color: rgba(80, 90, 94, 0.5);
  position: absolute;
  top: -6px;
  left: 100%;
}

.ta-oauth__account-item {
  border-bottom: 1px solid #c9c8c6;
  margin-bottom: 9px;
  height: 59px;
  padding-left: 60px;
  padding-right: 110px;
  position: relative;
}

.ta-oauth__account-item.auth-not-available {
  height: auto;
}

.ta-oauth__account-item__name {
  font-size: 14px;
  font-weight: 500;
  color: #505a5e;
  padding: 9px 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.ta-oauth__account-item.no-address .ta-oauth__account-item__name {
  line-height: 50px;
  padding: 0;
}

.ta-oauth__account-item__map {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 3px;
  border: 1px solid rgba(45, 47, 51, 0.2);
  background: #fff;
  text-align: center;
  overflow: hidden
}

.ta-oauth__account-item__address {
  color: #505a5e;
  font-weight: 300;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.ta-oauth__account-item__label {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  line-height: 24px;
  text-align: center;
  position: absolute;
  top: 13px;
  right: 20px;
  background: #f9f9f9;
  font-size: 9px;
}

.ta-oauth__account-item__initials {
  line-height: 3.5;
  vertical-align: middle;
  font-weight: bold;
  color: #505a5e;
}

.ta-oauth .ta-google-static-map__img {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
}

.ta-oauth__account-item__invitation__cta {
  position: absolute;
  top: 13px;
  right: 0;
  font-size: 10px;
  font-weight: 300;
  color: #505a5e;
  cursor: pointer;
  text-decoration: underline;
  display: none;
  background: rgba(203, 59, 67, 0.15);
  border-radius: 3px;
  padding: 0 8px;
  height: 25px;
  line-height: 25px;
}

.ta-oauth__account-item__invitation__cta i {
  margin-right: 5px;
}

.ta-oauth__account-item.is-pending .ta-oauth__account-item__invitation__cta {
  display: inline-block;
}

.ta-oauth__account-item__btn {
  position: absolute;
  top: 7px;
  right: 0;
  font-weight: bold;
}

.ta-oauth__account-item__warning {
  margin: 14px 0 6px;
  margin-left: -60px;
  margin-right: -110px;
  background: rgba(235, 182, 93, 0.15);
  border-radius: 3px;
  padding: 6px;
  padding-left: 22px;
  font-size: 12px;
  color: #505a5e;
  position: relative;
}

.ta-oauth__account-item.no-address .ta-oauth__account-item__warning {
  margin: 4px 0 6px;
  margin-left: -60px;
  margin-right: -110px;
}

.ta-oauth__account-item__warning i {
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
}
