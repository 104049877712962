.ta-stepper__step {
  height: 0;
  overflow: hidden;
}

.ta-stepper__step.active {
  height: auto;
}

.ta-stepper__step.active-prev {
  height: auto;
}

.ta-stepper__step.active-next {
  height: auto;
}

.ta-stepper__step.prev {
  pointer-events: none;
}

/* HORIZONTAL ANIMATIONS */

.ta-stepper.horizontal .ta-stepper__step.active-prev {
  animation-name: activePrev;
  animation-duration: 0.5s;
}

@keyframes activePrev {
  0% {
    transform: translateX(-10%);
    opacity: 0;
    height: 0
  }
  50% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    height: auto;
  }
}

.ta-stepper.horizontal .ta-stepper__step.active-next {
  animation-name: activeNext;
  animation-duration: 0.5s;
}

@keyframes activeNext {
  0% {
    transform: translateX(10%);
    opacity: 0;
    height: 0
  }
  90% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    height: auto;
  }
}

.ta-stepper.horizontal .ta-stepper__step.prev {
  animation-name: previous;
  animation-duration: 0.5s;
}

.ta-stepper.horizontal .ta-stepper__step.prev.skip {
  animation-duration: 0s;
}

@keyframes previous {
  0% {
    height: auto;
  }
  90% {
    transform: translateX(-10%);
    opacity: 0;
  }
  100% {
    height: 0;
  }
}

.ta-stepper.horizontal .ta-stepper__step.next {
  animation-name: next;
  animation-duration: 0.5s;
}

.ta-stepper.horizontal .ta-stepper__step.next.loading {
  animation-duration: 0s;
}

.ta-stepper.horizontal .ta-stepper__step.next.skip {
  animation-duration: 0s;
}

@keyframes next {
  0% {
    height: auto;
  }
  50% {
    transform: translateX(10%);
    opacity: 0;
  }
  100% {
    height: 0;
  }
}
