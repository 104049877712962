html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -moz-box-sizing: inherit;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

* {
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  height: 100%;
  background-color: #1d3247;
  overflow: hidden;
}

body {
  background: #1d3247 url('./Assets/images/ta-background.jpg') 0 0 no-repeat !important;
  background-size: cover !important;
}

img {
  border: none;
}

a {
  text-decoration: none;
}

input,
select,
textarea,
button {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 300;
}

strong {
  font-weight: 500;
}

iframe {
  border-radius: 3px;
}

input::-ms-clear {
  display: none;
}

.no-pad {
  padding: 0;
}

.pbottom-0 {
  padding-bottom: 0;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('https://cdn.timify.com/fonts/ubuntu/Ubuntu-BoldItalic.woff2') format('woff2'),
      url('https://cdn.timify.com/fonts/ubuntu/Ubuntu-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('https://cdn.timify.com/fonts/ubuntu/Ubuntu-Medium.woff2') format('woff2'),
      url('https://cdn.timify.com/fonts/ubuntu/Ubuntu-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('https://cdn.timify.com/fonts/ubuntu/Ubuntu-LightItalic.woff2') format('woff2'),
      url('https://cdn.timify.com/fonts/ubuntu/Ubuntu-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('https://cdn.timify.com/fonts/ubuntu/Ubuntu-Italic.woff2') format('woff2'),
      url('https://cdn.timify.com/fonts/ubuntu/Ubuntu-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('https://cdn.timify.com/fonts/ubuntu/Ubuntu-MediumItalic.woff2') format('woff2'),
      url('https://cdn.timify.com/fonts/ubuntu/Ubuntu-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('https://cdn.timify.com/fonts/ubuntu/Ubuntu-Bold.woff2') format('woff2'),
      url('https://cdn.timify.com/fonts/ubuntu/Ubuntu-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('https://cdn.timify.com/fonts/ubuntu/Ubuntu-Regular.woff2') format('woff2'),
      url('https://cdn.timify.com/fonts/ubuntu/Ubuntu-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('https://cdn.timify.com/fonts/ubuntu/Ubuntu-Light.woff2') format('woff2'),
      url('https://cdn.timify.com/fonts/ubuntu/Ubuntu-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
