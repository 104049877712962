.ta-color-icon-select {
  position: relative
}

.ta-single-select .ta-form-group .Select-control {
  padding-right: 50px;
}

.ta-color-icon-select .ta-hover-popup {
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 500;
  width: 50px;
  height: 32px;
  background: #fff;
  cursor: pointer;
}

.ta-color-icon-select__selected {
  width: 50px;
  height: 32px;
  border: 1px solid #505a5e;
  border-radius: 0 3px 3px 0;
}

.ta-color-icon-select__selected.disabled {
  opacity: 0.5;
  cursor: default;
  background-color: #e6e6e7;
}

.ta-color-icon-select__selected.disabled .ta-color-icon-select__options__color {
  cursor: default;
}

.ta-color-icon-select__selected .ta-color-icon-select__options__color {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%) translateZ(0);
}

.ta-color-icon-select__selected i.fa-sort {
  position: absolute;
  top: 10px;
  right: 9px;
  font-size: 12px;
  color: #505a5e;
}

.ta-color-icon-select__options {
  position: absolute;
  top: 4px;
  right: -4px;
  z-index: 501;
  width: 200px;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  padding: 10px 5px 5px 10px;
  border-radius: 3px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
}

.ta-color-icon-select__options.active {
  visibility: visible;
  opacity: 1;
}

.ta-color-icon-select__options .ta-color-icon-select__options__color {
  margin-right: 5px;
  margin-bottom: 5px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.ta-color-icon-select__options__color {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 3px;
  background: #eee;
  cursor: pointer;
  position: relative;
}

.ta-color-icon-select .ta-form-control {
  padding-right: 55px;
}

.ta-color-icon-select .ta-color-icon-select__options__color.dotted {
  background-image: url('../../../Assets/images/patterns/pattern-dots-light.png');
  background-repeat: repeat;
  background-size: 10px;
}

.ta-color-icon-select .ta-form-input-limit {
  margin-right: 48px;
}

.ta-color-icon-select .ta-form-input-with-limit {
  padding-right: 102px;
}
