.ta-color-select {
  position: relative
}

.ta-color-select .ta-hover-popup {
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 500;
  width: 60px;
  height: 40px;
  cursor: pointer;
}

.ta-color-select__selected {
  width: 60px;
  height: 40px;
  border: 1px solid rgba(80, 90, 94, 0.7);
  border-radius: 0 6px 6px 0;
  background: #fff;
}

.ta-color-select__selected.active {
  background-color: #f6f7f9;
  border-radius: 0 6px 0 0;
}

.ta-color-select__selected.disabled {
  cursor: default;
  background-color: #f7f8f8;
  border-color: rgba(182, 186, 189, 0.3);
}

.ta-color-select__selected.disabled .ta-color-select__options__color {
  cursor: default;
}

.ta-color-select__selected .ta-color-select__options__color {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%) translateZ(0);
}

.ta-color-select__selected i.fa-sort {
  position: absolute;
  top: 13px;
  right: 12px;
  font-size: 14px;
  color: rgba(80, 90, 94, 0.7);
}

.ta-color-select__selected.disabled i.fa-sort {
  color: rgba(80, 90, 94, 0.3);
  cursor: default;
}

.ta-color-select__selected.active:not(.disabled) i.fa-sort,
.ta-color-select__selected.hasValue:not(.disabled) i.fa-sort {
  color: #505a5e;
}

.ta-color-select__options {
  position: absolute;
  top: 39px;
  right: 0;
  z-index: 501;
  width: 94px;
  background-color: #f6f7f9;
  padding: 4px;
  border: 1px solid rgba(80, 90, 94, 0.7);
  border-radius: 6px 0 6px 6px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
}

.ta-color-select__options.active {
  visibility: visible;
  opacity: 1;
}

.ta-color-select__options .ta-color-select__options__color {
  margin: 4px;
}

.ta-color-select__options__color {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: #eee;
  background-color: #f6f7f9;
  cursor: pointer;
  position: relative;
}

.ta-color-select__options .ta-color-select__options__color:hover,
.ta-color-select__options .ta-color-select__options__color__active {
  border: 2px solid #fff;
  border-radius: 2px;
}

.ta-color-select__options .ta-color-select__options__color:not(:hover) {
  box-shadow: none;
}

.ta-color-select .ta-color-select__options__color.dotted {
  background-image: url('../../../Assets/images/patterns/pattern-dots-light.png');
  background-repeat: repeat;
  background-size: 10px;
}

.ta-color-select .ta-form-control {
  padding-right: 55px;
}

.ta-color-select .ta-form-input-limit {
  margin-right: 48px;
}

.ta-color-select .ta-form-input-with-limit {
  padding-right: 102px;
}
