.ta-single-select .Select-control {
  height: 32px;
  line-height: 30px;
  border: 1px solid rgba(80, 90, 94, 0.6);
  font-size: 13px;
  font-weight: 300;
  color: #505a5e;
  padding: 0 8px;
  border-radius: 3px;
  transition: all 0.5s;
  box-shadow: none;
}

.ta-single-select.is-focused:not(.is-open)>.Select-control {
  box-shadow: none;
  border-color: #385f87;
  background-color: rgb(235, 239, 243);
}

.ta-single-select.is-open .Select-control {
  border-radius: 3px 3px 0 0;
  background-color: rgb(235, 239, 243);
  box-shadow: 0 5px 10px 0 rgba(56, 95, 135, 0.15);
}

.ta-form-group.filled .ta-single-select .Select-control {
  border-color: #505a5e;
}

.ta-form-group.filled .ta-single-select.is-focused .Select-control, .ta-single-select.is-focused .Select-control {
  border-color: #385f87;
}

.ta-form-group.disabled .ta-single-select .Select-control {
  background-color: #e6e6e7;
  border-color: rgba(80, 90, 94, 0.6);
}

.ta-single-select .Select-input {
  height: 27px;
  line-height: 27px;
  padding-left: 0;
  position: relative;
  bottom: 3px;
  display: block;
}

.ta-single-select .Select-control .Select-placeholder, .ta-single-select .Select-control .Select-value {
  line-height: 31px;
  height: 31px;
  bottom: 0;
  top: 0;
}

.ta-single-select .Select-control .Select-input:focus {
  background: transparent;
}

.ta-single-select .Select-clear-zone {
  width: 24px;
  height: 24px;
  background: #848B8E;
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 100;
  border-radius: 3px;
}

.ta-single-select .Select-clear-zone .Select-clear:after {
  color: #fff;
  opacity: 1;
}

.ta-single-select .Select-clear {
  font-size: 0;
}

.ta-single-select .Select-clear:after {
  content: "\f00d";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 11px;
  color: #505a5e;
  transform: translate(-50%, -50%);
  opacity: 0.7;
}

.ta-single-select .Select-arrow-zone {
  padding-right: 0;
  position: relative;
  width: 10px;
  color: #505a5e;
  font-size: 12px;
}

.ta-single-select .Select-menu-outer {
  margin-top: 1px;
  box-shadow: none;
  border: 1px solid #385f87;
  border-radius: 0 0 3px 3px;
  background: #f6f7f9;
  padding: 5px;
  z-index: 100;
  background-color: rgb(235, 239, 243);
  box-shadow: 0 5px 10px 0 rgba(56, 95, 135, 0.15);
  max-height: 300px;
}

.ta-single-select .Select-option {
  border-bottom: 1px dashed #385f87;
  font-size: 13px;
  font-weight: 300;
  color: #969c9e;
  background: transparent;
  padding: 0 5px;
  height: 28px;
  line-height: 28px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ta-single-select .Select-option:before {
  content: "";
  border-top: 1px solid rgb(235, 239, 243);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.ta-single-select .Select-option:after {
  content: "";
  border-top: 1px solid rgb(235, 239, 243);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.ta-single-select .Select-option:hover {
  background-color: rgba(56, 95, 135, 0.1);
  color: #505a5e;
}

.ta-single-select .Select-option:last-child {
  border-bottom: none;
}

.ta-single-select .Select-option.is-selected {
  color: #505a5e;
  font-weight: normal;
}

.ta-single-select .Select-option.is-focused {
  background-color: rgba(56, 95, 135, 0.1);
  color: #505a5e;
}

.ta-single-select .Select-noresults {
  font-size: 11px;
  color: #969c9e;
  font-weight: 100;
}
