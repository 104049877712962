.ta-icon-avatar {
  position: relative;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border-radius: 50%;
  background: #eee;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ta-icon-avatar__icon {
  width: 48px;
  height: 48px;
}
