.ta-color-preview {
  height: 13px;
  width: 12px;
  border-radius: 3px;
  top: 0;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  position: absolute;
}

.ta-color-preview.dotted {
  background-image: url('../../Assets/images/patterns/pattern-dots-light.png');
  background-repeat: repeat;
  background-size: 10px;
}
