.ta-range-slider__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  vertical-align: center;
  user-select: none;
  justify-content: flex-end;
  height: 66px;
}

.ta-range-slider__content .ta-range-slider__label-container {
  display: flex;
  justify-content: space-between;
}

.ta-range-slider__content .ta-range-slider__label-container > * {
  width: 22px;
  height: 16px;
  margin: 10px 5px 0 5px;
  font-family: Ubuntu;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: rgba(80, 90, 94, 0.8);
  width: fit-content;
}

.ta-range-slider__body {
  position: relative;
  display: table-cell;
  width: calc(100% - 20px);
  position: relative;
  margin: 0 10px;
}

.ta-range-slider__left-value,
.ta-range-slider__right-value  {
  position: absolute;
  top: -28px;
  padding: 0 3px;
  text-align: center;
  transform: translateX(-50%);
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  text-align: center;
  height: 16px;
  border-radius: 2px;
  color:#fff;
  background-color: #505a5e;
}

.ta-range-slider__left-value:after,
.ta-range-slider__right-value:after{
  content: '';
  height: 4px;
  width: 4px;
  transform: rotate(45deg);
  background-color: #505a5e;
  bottom: -2px;
  display: inline-block;
  position: absolute;
  right:calc(50% - 2px);
}

.ta-range-slider__center {
  position: absolute;
  top: -17px;
  left: 100%;
  width: 20px;
  text-align: center;
  transform: translateX(-50%);
  font-size: 13px;
  font-weight: 500;
  color: #505a5e;
}

/* restriction point base style */
.ta-range-slider__body:before,
.ta-range-slider__body:after  {
  content: '';
  width: 8px;
  height: 12px;
  border-radius: 4px;
  background: #cbced0;
  position: absolute;
  top: 0px;
  z-index: -1;
}
/* restriction point left style */
.ta-range-slider__body:after {
  right: -4px;
}
/* restriction point right style */
.ta-range-slider__body:before{
  left: -4px;
}

/* handler base styles */
.ta-range-slider .rc-slider-handle {
  background: #fff;
  width: 20px;
  height: 20px;
  margin-top: -9px;
  border: 4px solid #b6babd;
  opacity: 1;
}
/* handler hover styles */
.ta-range-slider .rc-slider-handle:hover,
.ta-range-slider .rc-slider-handle.rc-slider-handle-dragging {
  background: #505a5e;
  border: 4px solid #fff;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ta-range-slider .rc-slider-handle:hover:after,
.ta-range-slider .rc-slider-handle.rc-slider-handle-dragging:after {
  content: '';
  width: 20px;
  height: 20px;
  border: 1px solid #505a5e;
  border-radius: 50%;
  position: absolute;
}

.ta-range-slider .rc-slider {
  height: 12px;
}

.ta-range-slider .rc-slider-step {
  height: 1px;
}

.ta-range-slider .rc-slider-rail {
  height: 8px;
  background: #f0f1f2;
  z-index: -2;
  top: 50%;
  transform: translateY(-50%);
}

.ta-range-slider .rc-slider-track {
  height: 8px;
  background: #b6babd;
  z-index: -2;
  top: 50%;
  transform: translateY(-50%);
}
