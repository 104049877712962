.ta-offline {
  position: relative;
  display: inline-block;
  box-sizing: border-box
}

.ta-offline-body {
  position: relative;
  display: inline-block;
  opacity: 0.2;
  pointer-events: none;
}

.ta-offline-icon {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.ta-offline-icon i {
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  margin: auto;
  width: 22px;
  height: 22px;
  color: #cb3b43;
  line-height: 22px;
  font-size: 12px;
  vertical-align: middle;
  text-align: center;
  background: #fff;
  border-radius: 50%;
}
