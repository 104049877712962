.ta-oval-counter {
  min-width: 24px;
  height: 24px;
  background-color: #505a5e;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}
