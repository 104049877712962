.ta-permission-types__custom-accordion.ta-accordion .ta-accordion__title:after {
  background: #cb3b43;
}

.ta-permission-types__custom-accordion.expand .ta-accordion__body {
  margin-top: 8px;
}

.ta-accordion .ta-accordion__title:after {
  background: #505a5e;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  font-size: 10px;
  top: 10px;
}

.ta-simple-list-item__buttons a {
  cursor: pointer;
}

.ta-customers__list {
  padding-bottom: 0;
}
