.ta-checkbox {
  display: inline-block;
  color: rgba(80, 90, 94, 0.6);
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  min-height: 20px;
  position: relative;
  padding-left: 28px;
  padding-top: 3px;
  user-select: none;
}

.ta-checkbox:hover {
  color: #505A5E;
  cursor: pointer;
}

.ta-checkbox.box {
  padding: 0 10px;
  border: 1px solid #505A5E;
  border-radius: 3px;
  font-weight: 500;
  font-size: 11px;
  color: #505A5E;
  line-height: 23px;
  cursor: pointer;
}

.ta-checkbox:after {
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  color: #385f87;
  text-align: center;
  width: 20px;
  height: 20px;
  line-height: 18px;
  background: #fff;
  border: solid 1px rgba(80, 90, 94, 0.4);
  border-radius: 4px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.ta-checkbox:hover:after {
  box-shadow: 0 0 5px 0 rgba(80, 90, 94, 0.3);
  border: solid 1px #505A5E;
  background-color: #EDEEEE;
}
.ta-checkbox.active:hover:after {
  border: solid 1px #505A5E;
}

.ta-checkbox.box:after {
  display: none;
}

.ta-checkbox.switch {
  padding-left: 46px;
}

.ta-checkbox.switch:before {
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background:#b9bdbf;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 5;
  transition: all 0.2s;
}

.ta-checkbox.box:before {
  display: none;
}

.ta-checkbox.switch:after {
  width: 38px;
  border-radius: 10px;
  transition: all 0.2s;
}

.ta-checkbox.active:after {
  content: "\f00c";
  color: #505a5e;
  font-size: 14px;
  font-weight: 400;
  border: solid 1px rgba(80, 90, 94, 0.7);
}

.ta-checkbox.active {
  color: #505a5e;
}

.ta-checkbox.box.active {
  background: #CB3B43;
  border-color: #CB3B43;
  color: #fff;
}

.ta-checkbox.switch.active:before {
  background: #769851;
  transform: translateX(16px);
  opacity: 1;
  left: 5px;
  top: 3px;
}

.ta-checkbox.switch.disabled.active:before, .ta-checkbox.switch.disabled:before {
  background-color: #cacdce;
}
.ta-checkbox.switch.disabled.active::after, .ta-checkbox.switch.disabled::after {
  background-color: #f7f8f8;
}

.ta-checkbox.switch.active:after {
  content: "";
}

.ta-checkbox.focused:after {
  border-color: #385f87;
}

.ta-checkbox.disabled {
  color: rgba(80, 90, 94, 0.4);
  cursor: default;
  pointer-events: none;
}

.ta-checkbox.disabled:after {
  border: solid 1px rgba(182, 186, 189, 0.3);
  color: rgba(80, 90, 94, 0.3);
  background-color: #f7f8f8;
}

.ta-checkbox.disabled:hover::after {
  box-shadow: none;
}

.ta-checkbox-field {
  position: absolute;
  top: 0;
  left: -9999px;
  z-index: 5;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
}

.ta-checkbox-wrapper.inline {
  display: inline-block;
  padding-right: 10px;
}

.ta-checkbox a {
  color: #505a5e;
  font-weight: 500;
  text-decoration: underline;
}

.ta-checkbox.boxed-label {
  position: static;
  display: inline-block;
  margin-left: 25px;
  height: 44px;
  padding: 12px;
  border-radius: 6px;
  border: solid 1px #cbced0;
  background-color: white;
  width: calc(100% - 25px);
  color: #1c272b;
  font-weight: bold;
  font-size: 14px;
}

.ta-checkbox.boxed-label:after {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.ta-checkbox-group__row-item:hover .ta-checkbox.boxed-label {
  background: #f6f8fa;
  border-color: #505a5e;
}

.ta-checkbox-group__row-item:hover .ta-checkbox.boxed-label,
.ta-checkbox-group__row-item:hover .ta-cross__account__checkbox_item_details {
  background: #f6f8fa;
  border-color: #505a5e;
}

.ta-checkbox-group__row-item.active .ta-checkbox.boxed-label,
.ta-checkbox-group__row-item.active .ta-cross__account__checkbox_item_details
{
  border: solid 1px #969c9e;
}
