.ta-booking-location-select__options__item {
  border-bottom: 1px dashed #385f87;
  font-size: 13px;
  font-weight: 300;
  color: #969c9e;
  padding: 8px;
  height: 48px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
  color: #505a5e;
}

.ta-booking-location-select__options__item:hover {
  background-color: rgba(56, 95, 135, 0.1);
}

.ta-booking-location-select__options__address {
  display: inline-block;
  overflow: hidden;
  font-size: 11px;
  line-height: 1.27;
  width: calc(100% - 2px);
  opacity: 0.7;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 4px;
  margin-top: 4px;
}