.ta-title {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ta-title__label {
  display: flex;
  align-items: center;
  min-width: 0;
  margin-right: 8px;
}

.ta-title__content {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.ta-title__label__addon {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.ta-title__label__icon {
  display: flex;
  background-color: #e5e6e7;
  padding: 6px;
  border-radius: 6px;
  margin-right: 12px;
}

.ta-title__label__text {
  font-size: 20px;
  font-weight: 500;
  color: #1c272b;
  letter-spacing: 0.2px;
  line-height: 1.6;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ta-title.ta-title__compact .ta-title__label__text {
  font-size: 14px;
  line-height: 1.5;
}

.ta-title.ta-title__centered {
  justify-content: center;
}

.ta-title.ta-title__centered .ta-title__label .ta-title__label__icon {
  position: absolute;
  left: 0;
}
