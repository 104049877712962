.ta-customers__preview.global .ta-simple-tabs.scrollable-content .ta-simple-tabs__body {
  top: 143px;
}

.ta-customers__preview.global .ta-message.active {
  min-height: 88px;
}

.ta-customers__preview.global.edited .ta-simple-tabs.scrollable-content .ta-simple-tabs__body,
.ta-customers__preview.global.marked-for-deletion .ta-simple-tabs.scrollable-content .ta-simple-tabs__body {
  top: 194px;
}

.ta-customers__preview .ta-btn-reset-global {
  margin-top: 16px;
  width: min-content;
}

.ta-customers__preview.global .ta-btn-delete-global {
  width: min-content;
  white-space: nowrap;
}

.ta-customers__preview.global .ta-message .ta-popup__trigger {
  display: block;
  margin-top: 16px;
}

.ta-customers__preview.global .ta-message .ta-popup__trigger {
  width: 65px;
}

.ta-customers__preview.global.marked-for-deletion .ta-message .ta-popup__trigger {
  width: 110px;
}

.ta-customers__preview__details .ta-simple-table {
  text-align: center;
}

.ta-customers__preview__details__header {
  position: relative;
  margin-bottom: 20px;
}

.ta-customers__preview__details__status {
  display: inline-block;
  padding: 0 5px;
  height: 12px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 10px;
  background: #b6babd;
  font-size: 8px;
  font-weight: 500;
  color: #505a5e;
  margin: 0 auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 10px;
}

.ta-customers__preview__bookings .ta-simple-list-item__title {
  margin-bottom: 0;
  line-height: 1;
}

.ta-customers__preview__details__status.active {
  background: #769851;
  color: #fff;
}

.ta-customers__preview__bookings__booked-online {
  background: #769851;
  height: 9px;
  width: 9px;
  border-radius: 50%;
  transform: translateY(4px);
}

.ta-customers__preview__details__owner {
  border: 2px solid #f4f3f3;
  background: #ecd007;
  font-size: 13px;
  color: #505a5e;
  width: 30px;
  height: 30px;
  line-height: 26px;
  text-align: center;
  border-radius: 30px;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 10;
  transform: translateX(14px);
}

.ta-customers__preview__details .ta-user-preview__name {
  margin-top: -5px;
  margin-bottom: 0;
}

.ta-customers__preview .ta-simple-tabs.scrollable-content .ta-simple-tabs__body-item {
  padding-bottom: 20px;
  min-height: 170px;
}

.ta-customers__preview .ta-simple-tabs.scrollable-content .ta-simple-tabs-changedLocally {
  min-height: unset;
}

.ta-customers__preview .ta-simple-tabs__body-item.ta-simple-tabs-courses,
.ta-customers__preview .ta-simple-tabs__body-item.ta-simple-tabs-customers {
  padding-top: 5px;
}

.ta-customers__preview .ta-customers__preview__courses .ta-no-results,
.ta-customers__preview .ta-customers__preview__customers .ta-no-results {
  margin-top: 15px;
}

.ta-customers__preview .ta-customers__preview__courses .ta-simple-list-item__title,
.ta-customers__preview .ta-customers__preview__customers .ta-simple-list-item__title {
  font-weight: 500;
  margin-bottom: 0;
  position: relative;
  top: 1px;
}

.ta-customers__preview__details__color {
  position: static;
}

.ta-customers__preview__details__confirmed {
  display: inline-block;
  background: rgba(118, 152, 81, 0.15);
  color: #769851;
  border-radius: 3px;
  padding: 0 5px;
  font-size: 11px;
  font-weight: 500;
  margin-left: 5px;
}

.ta-customers__preview__details__invitation {
  background: rgba(203, 59, 67, 0.15);
  color: #cb3b43;
  border-radius: 3px;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  font-weight: 500;
  margin-top: 5px;
}

.ta-customers__preview__details__invitation i {
  margin-right: 5px;
}

.ta-customers__preview__details__invitation__cta {
  float: right;
  color: #505a5e;
  font-weight: 300;
  cursor: pointer;
  text-decoration: underline;
}

.ta-customers__preview__bookings .ta-simple-list-item {
  height: 64px;
}

.ta-customers__preview__bookings .ta-simple-list-item.past {
  background: transparent;
  border: solid 1px #b6babd80;
}

.ta-customers__preview__bookings .ta-simple-list-item.deleted.no-pointer,
.ta-customers__preview__bookings .ta-simple-list-item.deleted {
  background: transparent;
  border: solid 1px #b6babd80;
  cursor: default;
}


.ta-customers__preview__bookings__timestamp,
.ta-customers__preview__bookings__checkbox {
  border-right: 1px solid rgba(216, 216, 216, 0.5);
  height: 100%;
  align-items: center;
  display: flex;
}

.ta-simple-list-item:hover 
.ta-customers__preview__bookings__timestamp, 
.ta-simple-list-item:hover 
.ta-customers__preview__bookings__checkbox {
  border-right-color: #505a5e;
}

.ta-customers__preview__bookings__timestamp ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 8px;
}

.ta-customers__preview__bookings__timestamp .ta-customers__preview__date {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #505a5e;
}

.ta-customers__preview__bookings__timestamp .ta-customers__preview__time {
  opacity: 0.8;
  font-size: 9px;
  font-weight: bold;
  line-height: 1.11;
  text-align: center;
  color: #505a5e;
}

.ta-customers__preview__bookings__timestamp .ta-avatar {
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 13px;
  margin: 0;
}

.ta-customers__preview__bookings__timestamp .ta-avatar__image,
.ta-customers__preview__bookings__timestamp .ta-avatar__image img {
  width: 100%;
  height: 100%;
  vertical-align: top;
}

.ta-customers__preview__bookings__timestamp__extra {
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 16px;
  background: #cb3b43;
  color: #fff;
  border: 1px solid #fff;
  position: absolute;
  top: -1px;
  left: 29px;
  font-size: 8px;
  font-weight: 800;
  text-align: center;
}

.ta-customers__preview .ta-simple-tabs__body-item .ta-address__map {
  padding: 8px;
}

.ta-customers__preview .ta-simple-tabs .ta-print-btn {
  float: right;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  background-color: #505a5e;
  color: #fff;
  font-size: 14px;
  line-height: 32px;
  border: none;
}

.ta-customers__preview__booking-select {
  width: 80%;
}

.ta-customers__preview__bookings .ta-row .ta-col.col-10 {
  vertical-align: bottom;
}

.ta-customers__preview__details .ta-form {
  padding-bottom: 0;
}

.ta-customers__preview__bookings .ta-form {
  padding-bottom: 0;
}

.ta-customers__preview__bookings__data-fields-preview {
  position: relative;
  z-index: 200;
}

.ta-customers__preview .ta-simple-box {
  margin-top: -4px;
  text-align: left;
  margin-bottom: 8px;
  padding: 0px 8px 4px 8px;
}

.ta-customers__preview .ta-list__title {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.33;
  color: #505a5e;
  margin-bottom: 4px;
}

.ta-customers__preview .ta-list__text {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.33;
  color: #505a5e;
  border-bottom: 1px solid #c9c8c6;
  padding-bottom: 8px;
}

.ta-customers__preview .ta-section-title {
  margin: 4px 0;
}

.ta-customers__preview__bookings .ta-simple-list-item,
.ta-customers__preview__bookings .ta-simple-list-item.no-pointer {
  cursor: pointer;
}

.ta-customers__preview__bookings-wrapper .ta-message.active {
  margin-bottom: 0;
}

.ta-customers__preview__bookings-wrapper .ta-bordered-box {
  border-radius: 0 0  3px 3px;
  min-height: 100px;
}

.ta-customers__preview__bookings-wrapper:last-child .ta-bordered-box {
  margin-bottom: 0;
}

.ta-customers__preview__bookings .ta-icon-list {
  border-bottom: none;
}

.ta-customers__bookings-container {
  margin-top: 8px;
  margin-bottom: 8px;
}

.ta-customers__bookings-loader-container { 
  min-height: 100px;
}

.ta-customers__booking-load-more-button {
  position: relative;
}

.ta-customers__preview__bookings .ta-icon-list .ta-icon-list__title {
  border-bottom: 1px dotted #919191;
  margin-right: 7px;
  vertical-align: baseline;
}

.ta-customers__preview__bookings .ta-icon-list .ta-icon-list__title.no-border {
  border-bottom: none;
}

.ta-customers__preview__bookings .ta-icon-list .ta-icon-list__body {
  border-bottom: 1px dotted #919191;
}

.ta-customer__preview--changed-locally--branch {
  min-height: 80px;
  border-radius: 3px;
  border: solid 1px rgba(182, 186, 189, 0.5);
  background-color: #ffffff;
  color: #5b6569;
  padding: 16px;
  margin: 5px 0;
  display: flex;
}

.ta-customer__preview--changed-locally--branch__data {
  width: 70%;
}

.ta-customer__preview--changed-locally--branch__buttons {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ta-customer__preview--changed-locally--branch__buttons button {
  font-size: 10px;
  font-weight: 500;
  color: #5b6569;
  background: #ecd007;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
}

.ta-customer__preview--changed-locally--branch__name {
  font-size: 15px;
  font-weight: 500;
}

.ta-customer__preview--changed-locally--item {
  display: flex;
  margin: 5px 0;
  font-size: 12px;
}

.ta-customer__preview--changed-locally--item i {
  margin-right: 5px;
}

.ta-customers__preview_booking_address .ta-simple-list-item__content,
.ta-customers__bookings-container .ta-simple-list-item__title-text {
  max-width: 290px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: normal;
}

.ta-customers__preview_booking_bookings_title {
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  text-transform: uppercase;
}

.ta-customers__preview_booking_details_button {
  margin-top: 16px;
  margin-bottom: 8px;
}

.ta-customers__preview_booking_branches_container .ta-icon-list__body {
  display: block;
  padding: 4px 0;
  font-size: 12px;
  font-weight: 300;
  color: #505a5e;
}

.ta-customers__preview_booking_branches_container .ta-icon-list {
  padding: 0;
}

.ta-customers__preview_booking_branches_container .ta-icon-list.no-icon:last-child .ta-icon-list__body {
  border-bottom: none;
}

.ta-customers__preview_booking_container .ta-simple-list-item {
  display: flex;
  padding: 0;
}

.ta-customers__preview_booking_container .ta-simple-list-item .ta-customers__preview__bookings__content {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 8px;
  height: 100%;
}

.ta-customers__preview__bookings__content .ta-simple-list-item__title {
  margin-bottom: 4px;
}
.ta-customers__preview__bookings__content .ta-customers__preview_booking_address {
  margin-bottom: 2px;
}

.ta-customers__preview__bookings__content .ta-list-inline {
  white-space: nowrap;
}

.ta-customers__preview__bookings__checkbox .ta-form-group {
  padding-top: 0;
}

.ta-customers__preview__bookings__checkbox .ta-checkbox:after {
  left: 50%;
  transform: translateX(-50%);
}
.ta-customers__preview__bookings__checkbox .ta-simple-list-item__content 
.ta-customers__preview__bookings__checkbox .ta-list-inline {
  line-height: 1;
}

.ta-bookings-buttons-container, 
.ta-bookings-buttons-container .ta-popup__trigger{
  width: 100%;
}

.ta-bookings__loader-container {
  width: 100%;
  display: block;
  position: relative;
  height: 100px;
}

.ta-customers__preview__event-resources {
  display: inline;
}

.ta-customers__preview__event-resources__label:hover {
  color: #505a5e
}

.ta-customers__preview__event-resources .ta-hover-popup {
  width: auto;
}

.ta-customers__preview__event-resources .ta-hover-popup__content {
  width: 224px;
  border-radius: 6px;
}

.ta-customers__preview__event-resources__count {
  text-transform: lowercase;
}