.ta-alert {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding: 12px 12px 12px 16px;
  margin: 0;
  color: var(--color-grey-100,#505a5e);
  max-height: 500px;
}

.ta-alert:not(.ta-alert__no-border)::before {
  content: '';
  width: 4px;
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.ta-alert.ta-alert__compact {
  padding: 4px 8px;
}

.ta-alert.ta-alert__compact .ta-alert__title,
.ta-alert.ta-alert__compact .ta-alert__icon {
  font-size: 10px;
}

.ta-alert.ta-alert__compact .ta-alert__content {
  font-size: 12px;
}

.ta-alert.ta-alert__compact .ta-alert__icon {
  left: 10px;
}

.ta-alert.ta-alert__compact .ta-alert__content.no-label {
  padding-left: 18px;
}

.ta-alert.ta-alert__top-offset {
  margin-top: 4px;
}

/* center */
.ta-alert.ta-alert__center {
  align-items: center;
  text-align: center;
}

/* no-content */
.ta-alert.no-content {
  border: dashed 1px var(--color-light-grey-70,#cbced0);
  background-color: var(--color-light-grey-10, #f7f8f8);
  padding: 12px;
}

/* success */
.ta-alert.success { 
  background: var(--color-green-15, #ECF0E7);
}

.ta-alert.success .ta-alert__title,
.ta-alert.success .ta-alert__icon { 
  color: var(--color-green-100,#769851); 
}

.ta-alert.success::before {
  background-color: var(--color-green-100,#769851);
}

/* alert */
.ta-alert.alert {
  background: var(--color-yellow-15, #FCF5E8);
}

.ta-alert.alert::before {
  background-color: var(--color-gold, #ebb65d);
}

.ta-alert.alert .ta-alert__title {
  color: var(--color-grey-100,#505a5e);
}

/* error */
.ta-alert.error {
  background: var(--color-red-15, #F7E2E3);
}

.ta-alert.error .ta-alert__title,
.ta-alert.error .ta-alert__icon { 
  color: var(--color-red-100,#cb3b43);
}

.ta-alert.error::before {
  background-color: var(--color-red-100,#cb3b43);
}

/* info */
.ta-alert.info { 
  background: var(--color-grey-10, #edeeee);
}

.ta-alert.info::before {
  background-color: var(--color-light-grey-70,#cbced0);
}

.ta-alert.info .ta-alert__title,
.ta-alert.info .ta-alert__content { 
  color: var(--color-grey-100,#505a5e); 
}

.ta-alert__close-btn {
  line-height: 1.33;
  color: var(--color-grey-100,#505a5e);
  cursor: pointer;
}

.ta-alert__icon {
  position: absolute;
  top: 50%;
  left: 17px;
  transform: translateY(-50%) translateZ(0);
}

.ta-alert__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: var(--color-grey-100,#505a5e);
}

.ta-alert__title .ta-alert__title-text-icon {
  font-size: 12px;
  margin-right: 8px;
}

.ta-alert__content {
  font-size: 12px;
  font-weight: 300;
  line-height: 1.33;
  margin-top: 12px;
  color: var(--color-grey-100,#505a5e);
}

.ta-alert__content.no-label {
  padding-left: 25px;
  margin-top: 0;
}

.ta-alert__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}

.ta-alert__buttons.only-hide {
  justify-content: end;
}

.ta-alert__buttons .ta-alert__buttons__hide {
  font-size: 12px;
  line-height: 1.17;
  color: var(--color-grey-50, #DADCDE);
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  text-decoration: underline;
}

@keyframes alert-show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes alert-hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}