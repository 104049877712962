.ta-version-indicator {
  position: fixed;
  left: calc(100% - 75px);
  bottom: 4px;
  z-index: 10000;
}

.ta-version-indicator__text {
  color: #fff;
  opacity: 0.5;
  font-size: 10px;
  letter-spacing: 0.5px;
  line-height: 14px;
}
