.ta-inner-accordion {
  width: 100%;
  padding: 16px;
  border-radius: 3px;
  background: rgba(56, 95, 135, 0.1);
  cursor: default;
  position: relative;
}

.ta-inner-accordion .ta-inner-accordion-header {
  position: relative;
  height: 64px;
  margin: -16px;
  padding: 16px;
}

.ta-inner-accordion .ta-inner-accordion-body {
  opacity: 0;
  position: relative;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s;
  visibility: hidden;
}

.ta-inner-accordion .ta-inner-accordion-body.open {
  visibility: visible;
  opacity: 1;
  max-height: 10000px;
  transition: all 0.5s;
  padding-top: 16px;
}

.ta-inner-accordion .ta-inner-accordion-icon-wrapper {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  float: left;
}

.ta-inner-accordion .ta-color-red {
  background: #cb3b43;
}

.ta-inner-accordion .ta-color-blue {
  background: #385f87;
}

.ta-inner-accordion .ta-color-green {
  background: #769851
}

.ta-inner-accordion .ta-color-grey {
  opacity: 0.7;
  background: #505a5e
}

.ta-inner-accordion .ta-inner-accordion-title {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: #505a5e;
  margin-left: 8px;
  width: calc(100% - 52px);
  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 8px;
}

.ta-inner-accordion .ta-inner-accordion-btn {
  right: 0;
  float: right;
  margin-top: 8px;
}
