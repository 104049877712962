.ta-agents__form__times .ta-simple-tabs__body {
  margin-bottom: 0;
}

.ta-agents__form__times .ta-message {
  margin-bottom: 0;
}

.ta-agents__form__avatar {
  float: left;
  width: 134px;
  margin: 0 3px;
}

.ta-agents__form__details {
  min-height: 134px;
}

.ta-agents__form__details-fields {
  padding-left: 150px;
}

.ta-agents__form__details-fields .ta-alert {
  margin-top: 8px;
}

.ta-agents__form .ta-title__extra_text {
  color: #a1a6a8;
  font-weight: normal;
  font-style: italic;
  font-size: 14px;
}

.ta-agents__form__details__unlink-box {
  position: relative;
}

.ta-agents__form__details__unlink-box.pending:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
  z-index: 2;
}

.ta-agents__form__details__unlink-box .ta-spinner {
  z-index: 4;
}

.ta-agents__form__details__unlink-box .ta-popup__trigger {
  margin-left: 5px;
}

.ta-agents__form__details__unlink-box .ta-btn {
  height: 24px;
  line-height: 24px;
}

.ta-agents__form__permissions__overlay {
  display: block !important;
}

.ta-agents__form__permissions__overlay__trigger {
  position: relative;
  height: 32px;
  background: #f4f3f3;
  opacity: 0.5;
  margin-bottom: -37px;
  z-index: 100;
  border-radius: 4px;
}

.ta-agents__form__permissions__select {
  width: 100%;
}

.ta-agents__form .ta-form-section {
  margin-bottom: 8px;
}

.ta-agents__form .ta-section-title {
  margin: 8px 0;
}

/* IE FIX */

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .ta-agents__form__avatar .ta-image-input__sliders {
    display: none;
  }
}

.ta-agents__form__permissions .ta-agents__form__acl {
  border-radius: 3px;
  border: solid 1px rgba(182, 186, 189, 0.5);
  padding: 8px;
  margin-top: 8px;
}


.ta-hover-popup.ta-agents__form__permissions__select {
  width: 100%
}