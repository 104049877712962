.ta-text {
  color: #505a5e;
  font-size: 13px;
  font-weight: 400;
  display: block;
  line-height: 14px;
}

.ta-text.bold {
  font-weight: 500;
}

.ta-text.thin {
  font-weight: 300;
}

.ta-text.small {
  font-size: 11px;
}

.ta-text.big {
  font-size: 16px;
}

.ta-text.inline {
  display: inline;
}

.ta-text.small-light-height {
  line-height: 12px;
}

.ta-text.big-light-height {
  line-height: 16px;
}
