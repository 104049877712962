.ta-settings__header-title {
  padding: 20px 0 15px;
}

.ta-settings .ta-title__content .ta-btn,
.ta-settings__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-settings .ta-title__content > :not(:first-child),
.ta-settings__preview .ta-title__content > :not(:first-child) {
  margin-left: 4px;
}

.ta-settings .ta-title__content .ta-title__link {
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 4px;
  display: inline-block;
}

.ta-settings .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-settings__title {
  font-size: 13px;
  line-height: 1.23;
  color: #5a6569;
  font-weight: normal;
  margin-bottom: 8px;
}

.ta-settings__box-container  {
  margin-top: 8px;
}

.ta-settings__desc {
  font-size: 12px;
  line-height: 1.33;
  color: #505a5e;
  font-weight: normal;
}

.ta-settings_list li {
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  color: #505a5e;
  position: relative;
  padding-left: 10px;
}

.ta-settings_list li::before {
  content: " ";
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: #505a5e;
  position: absolute;
  left: 0;
  top: 5px;
}

.ta-settings__desc strong {
  font-weight: bold;
}

.ta-settings_list {
  margin-top: 8px;
}

.ta-settings-form .ta-section-title {
  margin-top: 0;
  margin-bottom: 8px;
}

.ta-settings__resources-li {
  margin: 4px 0 8px;
}

.ta-settings__resources-li.ta-list.bullet-item span{
  display: inline;
}