.ta-multi-fields-select {
  position: relative;
}

.ta-multi-fields-select__input {
  position: relative;
  margin-bottom: 8px;
}

.ta-multi-fields-select__input.has-delete {
  padding-right: 28px;
}

.ta-multi-fields-select-switch-btn {
  width: 24px;
  height: 24px;
  padding: 0;
  text-align: center;
  line-height: 24px;
}

.ta-multi-fields-select__input__btn-delete {
  cursor: pointer;
  width: 24px;
  height: 32px;
  line-height: 32px;
  background: #cb3b43;
  color: #fff;
  border-radius: 3px;
  text-align: center;
  position: absolute;
  top: 5px;
  right: 0;
  font-size: 11px;
}

.ta-multi-fields-select__input__btn-reorder {
  cursor: pointer;
  width: 24px;
  height: 32px;
  line-height: 32px;
  background: #b6babd;
  color: #505a5e;
  border-radius: 3px;
  text-align: center;
  position: absolute;
  top: 5px;
  right: 0;
  font-size: 11px;
  cursor: grab;
}

.ta-multi-fields-select__btn-add {
  background: #b6babd;
  border-radius: 3px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #505a5e;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: 4px;
  cursor: pointer;
}

.ta-multi-fields-select__dnd-wrapper {
  left: 15px !important;
}

.ta-multi-fields-select-switch-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  text-align: right;
  height: 30px;
}
