.ta-list-button {
  background: #b6babd;
  color: #505a5e;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  width: 24px;
  cursor: pointer;
}

.ta-list-button:hover {
  background: #cb3b43;
  color: #fff;
}

.ta-list-button.square {
  width: 50px;
}

.ta-list-button.has-text {
  width: auto;
  font-size: 12px;
  font-weight: bold;
  padding: 0 10px;
}

.is-dragging .ta-list-button {
  background: #cb3b43;
  color: #fff;
}

.ta-simple-list-item__buttons .ta-hover-popup {
  margin-left: 4px;
}
