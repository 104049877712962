.ta-agents__preview__permissions__item__label {
  position: absolute;
  top: 5px;
  left: 17px;
}

.ta-agents__preview__permissions__item__content {
  display: inline-block;
  padding-left: 80px;
  margin-top: -3px;
  transform: translateY(-2px) translateZ(0);
}

.ta-agents__preview__permissions__item__content__badge {
  border-radius: 3px;
  height: 16px;
  color: #fff;
  font-size: 9px;
  font-weight: 500;
  line-height: 16px;
  vertical-align: middle;
  padding: 0 3px;
  display: inline-block;
  margin: 2px;
}

.ta-agents__preview__permissions__item .ta-dotted-timeline__item.big {
  padding-bottom: 0;
}

.ta-agents__preview__details__permissions .ta-agents__preview__details__permissions__text {
  max-width: 335px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 17px;
}

.ta-agents__preview__details__permissions .ta-hover-popup__content {
  white-space: normal;
}

.ta-agents__preview__details__permissions .ta-inner-accordion .ta-inner-accordion-title {
  overflow: visible;
}

.ta-agents__preview__details__permissions .ta-accordion__title:after {
  top: 11px;
}

.ta-accordion.ta-permission-types__custom-accordion .ta-accordion__title:after {
  background: #cb3b43;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  font-size: 10px;
  top: 12px;
  content: "\f0d7";
  line-height: 2;
}

.ta-accordion.ta-permission-types__custom-accordion.expand .ta-accordion__title:after {
  content: "\f0d8";
  line-height: 1.8;
}

.ta-accordion.ta-permission-types__custom-accordion .ta-title__label {
  vertical-align: middle;
}