.ta-code-highlight {
  background: #1d3247;
  padding: 10px 15px;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
}

.ta-code-highlight:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 10px;
  background: #1d3247;
  box-shadow: #1d3247 0 0 30px 15px;
}

.ta-code-highlight.with-copy-button:after {
  width: 40px;
}

.ta-code-highlight code {
  color: #ecd007;
}

.ta-code-highlight .hljs-string {
  color: #fff;
}