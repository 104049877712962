.ta-overlay {
  height: 100%;
  width: 100%;
  transition: filter 0.8s;
}

.ta-overlay.active {
  filter: blur(4px);
  transition: filter 0.8s;
}

.ta-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: all 0.8s;
  transition: opacity 0.8s;
  background: linear-gradient(to top, #1d3247, #385f87);
}

.ta-overlay.active::after {
  content: "";
  transition: opacity 0.8s;
  opacity: .4;
}