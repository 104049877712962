/* .ta-customers.access-granted .ta-fixed-content:before,
.ta-customers.access-granted .ta-fixed-content__body {
  top: 221px;
} */

.stack-list ul li {
  display: flex;
  flex-direction: column;
}

.stack-list ul li span {
  margin-left: 0 !important;
}

.ta-customers .ta-fixed-content__body {
  padding-top: 15px;
}

.ta-customers:not(.access-granted) .ta-fixed-content__body {
  height: max-content;
}

.ta-customers.access-granted .ta-simple-tabs.scrollable-content .ta-simple-tabs__body {
  top: 45px;
  background: #fff;
}

.ta-customers.access-granted .ta-simple-tabs.scrollable-content .ta-simple-tabs__body-item {
  overflow: visible;
}

.ta-customers .ta-simple-list-item {
  border: 1px solid rgba(182, 186, 189, 0.5)
}

.ta-customers__list {
  width: 100%;
  height: 100%;
  margin-top: 8px;
}

.ta-customers__list.no-margin {
  margin-top: 0;
}

.ta-customers:not(.access-granted) .ta-customers__list {
  min-height: 200px;
}

.ta-customers__list .ta-simple-list-item:hover:after {
  top: 20px;
  left: 50%;
}

.ta-customers__list .ta-fixed-content__body {
  top: 143px
}

.ta-customers.access-granted .ta-fixed-content__header .ta-col .ta-hover-popup {
  width: 100%;
}

.ta-customers__button {
  display: inline-block;
  border: 1px solid #505a5e;
  border-radius: 3px;
  height: 20px;
  line-height: 18px;
  padding: 0 20px 0 5px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 400;
  color: #505a5e;
  position: relative;
}

.ta-customers__button:hover {
  color: #cb3b43;
  border-color: #cb3b43;
}

.ta-customers__button i {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%) translateZ(0);
}

.ta-customers__filter-alphabet {
  display: table;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.ta-customers__filter-alphabet-letter {
  display: table-cell;
  cursor: pointer;
  font-size: 12px;
  color: #505a5e;
  padding: 2px 3.8px;
}

.ta-customers__filter-alphabet-letter.active {
  background: #396088;
  color: #fff;
  border-radius: 3px;
}

.ta-customers__filter-alphabet-letter.disabled {
  color: #c4c7c9;
  cursor: auto;
  pointer-events: none;
}

.ta-customers__bookings-count {
  text-align: center;
  color: #fff;
  background: #385f87;
  border: 1px solid #385f87;
  border-radius: 2px;
  width: 64px;
  height: 64px;
  font-size: 9px;
  font-weight: 300;
  padding: 5px;
  line-height: 12px;
}

.ta-customers__bookings-count strong {
  display: block;
  font-size: 18px;
  margin: 6px 0;
}

.ta-customers__bookings-count.empty {
  border-color: rgba(182, 186, 189, 0.5);
  background: transparent;
  color: #505a5e;
}

.ta-popup__customers-help-box iframe {
  margin-top: 8px;
}

.ta-customers .ta-form {
  padding-bottom: 0;
}

.ta-customers__list .ta-sortable-list {
  margin-top: 5px;
}

.ta-customers__form .ta-fixed-content__body {
  padding-top: 7px;
}

.ta-customers__preview .ta-fixed-content__body {
  padding-top: 15px;
}

.ta-customers__list .ta-hover-popup {
  width: 100%;
}

.ta-customers__list .ta-simple-tabs__header-item .ta-hover-popup {
  margin-left: 0;
}

.ta-customers__list .ta-simple-list-item__buttons .ta-hover-popup {
  width: auto;
  color: #505a5e;
}

.ta-customers__list .ta-hover-popup:hover {
  color: #385f87;
}

.ta-customers__customer.updated {
  position: relative;
}

.ta-customers__customer.updated:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  opacity: 0;
  animation-duration: 2s;
  animation-name: customer-updated-before;
  animation-iteration-count: 1;
}

@keyframes customer-updated-before {
  0% {
    opacity: 1;
    background: rgba(244, 243, 243, 1);
  }

  100% {
    opacity: 0;
    background: rgba(244, 243, 243, 0);
  }
}

.ta-customers__customer.updated:after {
  content: "\f021";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  z-index: 11;
  opacity: 0;
  animation-duration: 2s;
  animation-name: customer-updated-after;
  animation-iteration-count: 1;
}

@keyframes customer-updated-after {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ta-customers__customer.deleted {
  position: relative;
}

.ta-customers__customer.deleted:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  animation-duration: 2s;
  animation-name: customer-deleted-before;
  animation-iteration-count: 1;
}

@keyframes customer-deleted-before {
  0% {
    background: rgba(244, 243, 243, 0);
  }

  15% {
    background: rgba(244, 243, 243, 0.7);
  }

  100% {
    background: rgba(244, 243, 243, 1);
  }
}

.ta-customers__customer.deleted:after {
  content: "\f1f8";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  z-index: 11;
  animation-duration: 2s;
  animation-name: customer-deleted-after;
  animation-iteration-count: 1;
}

@keyframes customer-deleted-after {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ta-customers__category {
  position: relative;
  padding: 0 0 15px 0;
}

.ta-customers__category .ta-section-title {
  margin-top: 0;
}

.ta-customers__category .ta-section-title .ta-btn {
  cursor: grab;
}

.ta-customers__category.first .ta-hover-popup__content.left {
  top: 0;
  transform: translateY(-8px) translateZ(0);
}

.ta-customers__category.first .ta-hover-popup__content.left:after {
  top: 11px;
  transform: translateY(0) translateZ(0);
}

.ta-customers__category.pending:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f4f3f3;
  opacity: 0.5;
  z-index: 99;
}

.ta-customers__category.pending:after {
  content: "\f110";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  left: calc(50% - 14px);
  top: calc(50% - 14px);
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  color: #505a5e;
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
  z-index: 100;
}

.ta-customers__category.deleted {
  position: relative;
}

.ta-customers__category.deleted:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  animation-duration: 2s;
  animation-name: customer-category-deleted-before;
  animation-iteration-count: 1;
}

@keyframes customer-category-deleted-before {
  0% {
    background: rgba(244, 243, 243, 0.5);
  }

  15% {
    background: rgba(244, 243, 243, 0.7);
  }

  100% {
    background: rgba(244, 243, 243, 1);
  }
}

.ta-customers__category.deleted:after {
  content: "\f1f8";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  animation-duration: 2s;
  animation-name: customer-category-deleted-after;
  animation-iteration-count: 1;
}

@keyframes customer-category-deleted-after {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ta-customers .ta-simple-list-item {
  min-height: 72px;
}

.ta-customers .ta-simple-list-item .ta-list-button {
  cursor: pointer;
  height: 65px;
}

.ta-customers .ta-simple-list-item .ta-list-button:hover {
  background: #b6babd;
  color: #505a5e;
}

.ta-customers.categories-collapse .ta-customers__list {
  max-height: 50px;
  overflow: hidden;
}

.ta-customers.categories-collapse .ta-customers__list {
  position: relative;
}

.ta-customers.categories-collapse .ta-customers__list:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.ta-customers.categories-collapse .ta-customers__list:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  z-index: 99;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(244, 243, 243, 1) 90%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(244, 243, 243, 1) 90%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(244, 243, 243, 1) 90%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#f4f3f3', GradientType=0);
}

.ta-customers.categories-collapse .ta-list-button {
  display: none;
}

.ta-customers__customer__avatar .ta-avatar {
  width: 56px;
  height: 56px;
  line-height: 56px;
  font-size: 18px;
  position: absolute;
}

.ta-customers__customer__avatar .ta-avatar .ta-avatar__image {
  width: 100%;
  height: 100%;
}

.ta-customers__customer__avatar .ta-avatar .ta-avatar__image img {
  width: 100%;
  height: 100%;
}

.ta-customers__customer .ta-blur__content__icon {
  width: 24px;
  height: 24px;
  line-height: 23px;
  margin-bottom: 4px;
}

.ta-customers__customer.opened {
  border-bottom: none;
  margin-bottom: 0;
  border-radius: 3px 3px 0 0;
}

/* ***********************************************

FIX DND COMPONENT

Issue: https://github.com/atlassian/react-beautiful-dnd/issues/703
Remove left prop when fix is provide

*********************************************** */

.ta-customers__category__dnd-wrapper,
.ta-customers__customer__dnd-wrapper {
  left: 15px !important;
}

.ta-customers__category__dnd-wrapper.is-dragging .ta-customers__category {
  opacity: 0.8;
}

.ta-customers__customer__dnd-wrapper.is-dragging .ta-simple-list-item {
  opacity: 0.8;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.5);
}

.ta-customers .ta-message {
  margin-top: 12px;
}

.ta-customers .ta-simple-list-item__title-icon {
  margin-left: 0;
  vertical-align: middle;
  top: -5px;
}

.ta-customers .ta-simple-list-item__title-icon .global-label {
  font-size: 10px;
  border: 1px solid #b6babd;
  color: #505a5e;
  line-height: 1.2;
  padding: 1px 4px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ta-customers .ta-simple-list-item__title-icon .global-label.edited {
  border: solid 1px rgba(235, 182, 93, 0.4);
  background-color: rgba(235, 182, 93, 0.2);
}

.ta-customers .ta-simple-list-item__title-icon .global-label.deleted {
  border: solid 1px rgba(203, 59, 67, 0.4);
  background-color: rgba(203, 59, 67, 0.2);
}

.ta-customers .ta-simple-list-item__title-icon .ta-hover-popup__trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.ta-customers .ta-simple-list-item__title-icon .ta-hover-popup__trigger i {
  margin-right: 2px;
  transform: translateY(-1px);
}

.ta-customers-to-delete--branch {
  min-height: 80px;
  border-radius: 3px;
  border: solid 1px rgba(182, 186, 189, 0.5);
  background-color: #ffffff;
  color: #5b6569;
  padding: 16px;
  margin: 5px 0;
  display: flex;
}

.ta-customers-to-delete .ta-simple-list-item {
  padding-right: 10px;
}

.ta-customers-to-delete .ta-message {
  margin-top: 0;
}

.ta-customers-to-delete--branch__data {
  width: 70%;
}

.ta-customers-to-delete--branch__buttons {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ta-customers-to-delete--branch__buttons button {
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  background: #cb3b43;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
}

.ta-customers-to-delete--branch__name {
  font-size: 15px;
  font-weight: 500;
}

.ta-customers-to-delete--item {
  display: flex;
  margin: 5px 0;
  font-size: 12px;
}

.ta-customers-to-delete--item i {
  margin-right: 5px;
}

.ta-customers-list-item__avatar,
.ta-customers-list-item__avatar .ta-avatar,
.ta-customers-list-item__avatar .ta-avatar__image,
.ta-customers-list-item__avatar .ta-avatar__image img {
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.ta-customers__customer__body ul {
  padding-top: 7px;
}

.ta-customers__customer__body ul li {
  width: 316px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: normal;
}

.ta-customers__customer__body ul li span {
  margin-top: 4px;
}

.ta-customers-list-item__info_item:not(:first-child) {
  margin-left: 10px;
}

.ta-customers-list-item__info_item {
  font-size: 12px;
  line-height: normal;
  color: #848b8e;
}

.ta-customers-list-item__info_item i {
  color: #848b8e;
}

.ta-customers-list-item__spinner-container {
  position: relative;
  height: 100px;
}

.ta-customers__customer__body ul li:first-child {
  font-size: 15px;
  font-weight: 500;
  color: #505a5e;
}

.ta-customers__customer__body .ta-list-button {
  width: 24px;
  border-radius: 3px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  line-height: 65px;
}

.ta-customers__customer__body .ta-list-button i {
  font-size: 14px;
  text-align: center;
  color: #505a5e;
  margin: 0;
}

.ta-customers__preview__bookings .ta-list-button {
  line-height: 65px;
}

.ta-customers__customer__body .ta-list-button.ta-btn-primary,
.ta-customers__preview__bookings .ta-list-button.ta-btn-primary {
  background-color: #cb3b43;
}

.ta-customers__customer__body .ta-list-button.ta-btn-primary:hover,
.ta-customers__preview__bookings .ta-list-button.ta-btn-primary:hover {
  background-color: #ab3138;
}

.ta-customers__customer__body .ta-list-button.ta-btn-primary i,
.ta-customers__customer__body .ta-list-button.ta-btn-primary:hover i,
.ta-customers__preview__bookings .ta-list-button.ta-btn-primary i,
.ta-customers__preview__bookings .ta-list-button.ta-btn-primary:hover i {
  color: #ffffff;
}

.ta-booking .ta-title__content .ta-simple-list-item.ta-customers__customer.no-pointer {
  padding: 0;
  width: 100%;
}

.ta-booking .ta-title__content .ta-simple-list-item.ta-customers__customer.no-hover:hover {
  border-color: transparent;
  background: transparent;
}

.ta-booking .ta-simple-list-item.ta-customers__customer:last-child {
  margin-bottom: 0;
}

.ta-simple-list-item__content .ta-col.col-10 {
  vertical-align: middle;
}

.ta-service-adhoc__header-content .ta-service-adhoc__title,
.ta-service-adhoc__header-content .ta-service-adhoc__row,
.ta-service-adhoc__header-content .ta-service-adhoc__row.right {
  margin-bottom: 4px;
}

.ta-customers__preview__bookings .ta-list-inline li {
  font-size: 12px;
  font-weight: 500;
  color: #848b8e;
}

.ta-customers__preview__bookings .ta-simple-list-item__title-text {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.07;
  color: #505a5e;
}

.search_result__total {
  height: 12px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.5px;
  color: #505a5e;
  margin: 8px 0;
}

.ta-customers-list-item__selected-booking__header {
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #505a5e;
  text-transform: uppercase;
}

.ta-customers-list-item__selected-booking__container {
  width: 100%;
  height: 64px;
  border-radius: 3px;
  border: solid 1px #737b7e;
  display: flex;
}

.ta-customers-list-item__selected-booking__left {
  width: 68px;
  border-right: solid 1px #737b7e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ta-customers-list-item__selected-booking__date {
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
}

.ta-customers-list-item__selected-booking__time {
  opacity: 0.8;
  font-size: 9px;
  font-weight: bold;
  line-height: 1.11;
  letter-spacing: normal;
  color: #505a5e;
}

.ta-customers-list-item__selected-booking__right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  min-width: 0;
  padding: 0 8px;
}

.ta-customers-list-item__selected-booking__name {
  margin-bottom: 4px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.07;
  color: #505a5e;
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ta-customers-list-item__selected-booking__branch-name {
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  color: #505a5e;
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ta-customers-list-item__selected-booking__info-container {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 13px;
  color: #848b8e;
  text-align: left;
  white-space: nowrap;
  max-width: 100%;
}

.ta-customers-list-item__selected-booking__resources {
  display: flex;
  align-items: center;
  max-width: 100%;
  min-width: 0;
}

.ta-simple-list-item__content .ta-customers-list-item__selected-booking__resources i {
  margin-right: 9px;
}

.ta-customers-list-item__selected-booking__row {
  table-layout: fixed;
}