.ta-simple-tabs__header {
  position: relative;
}

.ta-simple-tabs__header:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 15px;
  z-index: 990;
  background: -moz-linear-gradient(top, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 0) 100%);
  background: linear-gradient(to bottom, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( endColorstr='#f4f3f3', startColorstr='#00ffffff', GradientType=0);
}

.ta-simple-tabs__header-item {
  display: inline-block;
  background: rgba(182, 186, 189, 0.7);
  border-radius: 3px 3px 0 0;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  font-weight: 500;
  color: #505a5e;
  margin-right: 5px;
  opacity: 0.8;
  cursor: pointer;
}

.ta-simple-tabs__header-item:hover {
  opacity: 1;
  background: rgba(135, 137, 140, 0.58);
}

.ta-simple-tabs__header-item.active {
  opacity: 1;
  background: #f4f3f3;
}

.ta-simple-tabs__header-item.disabled {
  cursor: default;
  background: rgba(182, 186, 189, 0.35);
  color: rgba(80, 90, 94, 0.5)
}

.ta-simple-tabs__header-item .ta-hover-popup__trigger {
  padding: 0 10px;
}

.ta-simple-tabs__header-item i {
  margin-right: 5px;
}

.ta-simple-tabs__header-item.icon-only i {
  margin-right: 0;
}

.ta-simple-tabs__header-item.pull-right {
  float: right;
  margin-right: 0;
  margin-left: 5px;
}

.ta-simple-tabs__body {
  margin-bottom: 20px;
}

.ta-simple-tabs__body-item {
  background: #f4f3f3;
  border-radius: 0 0 3px 3px;
  display: none;
  padding: 20px;
}

.ta-simple-tabs__body-item.active {
  display: block;
}

.ta-simple-tabs__body hr {
  border: none;
  border-top: 1px solid #c9c8c6;
}

.ta-simple-tabs.scrollable-content .ta-simple-tabs__body {
  position: absolute;
  top: 45px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  margin: 0;
}

.ta-simple-tabs.scrollable-content .ta-simple-tabs__body-item {
  max-height: 100%;
  overflow: auto;
  padding-bottom: 12px;
}

.ta-simple-tabs__header:last-child {
  margin-right: 5px;
}
