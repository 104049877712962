.ta-simple-box {
  text-align: center;
  color: #396088;
  font-size: 18px;
  font-weight: 500;
  padding: 9px;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #fff;
  position: relative;
}

.ta-simple-box.big {
  padding: 15px;
  font-size: 36px;
}

.ta-simple-box__header {
  font-size: 12px;
  font-weight: 900;
  text-align: left;
  color: #505a5e;
  height: 32px;
  line-height: 32px;
  background: rgba(0, 0, 0, 0.2);
  margin: -10px;
  margin-bottom: 10px;
  border-radius: 3px 3px 0 0;
  padding: 0 10px;
}

.ta-simple-box__header .ta-statistics-box__label {
}

.ta-simple-box__header .ta-popup__trigger {
  font-size: 16px;
  color: rgba(80, 90, 94, 0.6);
  cursor: pointer;
}

.ta-simple-box.big .ta-simple-box__header {
  margin: -16px;
  margin-bottom: 15px;
  font-size: 14px;
  height: 35px;
  line-height: 35px;
}

.ta-simple-box__content {
  font-size: 9px;
  font-weight: 400;
  margin-top: 5px;
  color: #505a5e;
}

.ta-simple-box.color-grey {
  border-color: rgba(182, 186, 189, 0);
  background-color: rgba(182, 186, 189, 0.5);
  color: #505a5e;
}

.ta-simple-box.color-green {
  border-color: #769851;
  background-color: #769851;
  color: #fff;
}

.ta-simple-box.color-green .ta-simple-box__content {
  color: #fff;
}

.ta-simple-box.color-blue {
  border-color: #385f87;
  background-color: #385f87;
  color: #fff;
}

.ta-simple-box.color-blue .ta-simple-box__content {
  color: #fff;
}

.ta-simple-box.color-purple {
  border-color: #b26492;
  background-color: #b26492;
  color: #fff;
}

.ta-simple-box.color-purple .ta-simple-box__content {
  color: #fff;
}

.ta-simple-box.color-grey .ta-simple-box__header,
.ta-simple-box.color-green .ta-simple-box__header,
.ta-simple-box.color-blue .ta-simple-box__header,
.ta-simple-box.color-purple .ta-simple-box__header {
  color: #fff;
}

.ta-simple-box.color-none {
  border-color: rgba(182, 186, 189, 0.5);
  background-color: transparent;
  color: #505a5e;
}

.ta-simple-box .ta-simple-box__header__extra-text {
  font-weight: normal;
}

.ta-simple-box .ta-simple-box-label {
  margin-top: 4px;
  display: flex;
  font-weight: 500;
}

.ta-simple-box .ta-simple-box-label-button {
  min-height: 14px;
  padding: 0 4px;
  line-height: 14px;
  font-size: 8px;
  font-weight: 900;
  background: #b0b5b6;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  text-transform: uppercase;
}

.ta-statistics__sign {
  margin: 0 4px;
  font-size: 10px;
  font-weight: 500;
  color: #505a5e;
  line-height: 1.4;
}

.ta-simple-box .ta-simple-box-label-text {
  color: rgba(80, 90, 94, 0.8);
  font-size: 10px;
  margin-right: 4px;
  text-transform: uppercase;
}

.ta-simple-box .ta-simple-box-label-text > a {
  color: rgba(56, 95, 135, 0.8);
}

.ta-simple-box .ta-statistics-box__label {
  width: 20px;
  height: 14px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ta-simple-box .ta-statistics-box__label i {
  position: relative;
  left: 0.5px;
  color: #fff;
  font-size: 8px;
}

/* .ta-simple-box .ta-hover-popup.ta-statistics__label:not(:last-of-type) {
  margin-right: 4px;
} */

.ta-simple-box .ta-hover-popup.ta-statistics__label .ta-hover-popup__content {
  text-transform: initial;
  line-height: 1;
  text-align: center;
  letter-spacing: normal;
  max-inline-size: max-content;
}

.ta-simple-box .ta-hover-popup.ta-statistics__info-popup {
  position: absolute;
  bottom: 0;
  right: 0;
}

.ta-statistics-box__popup-icon {
  width: 20px;
  height: 20px;
  border-top-left-radius: 3px;
  background: #cbced0;
  color: rgba(80, 90, 94, 0.6);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ta-simple-box .ta-spinner {
  font-size: 16px;
  transform: translateY(15px) translateX(-10px);
}

.ta-simple-box__customers-count {
  font-weight: 900;
}