.ta-sidebar {
  position: absolute;
  top: 0;
  left: 75px;
  left: 0;
  bottom: 0;
  width: 500px;
  z-index: 1000;
  transform: translateX(-100%);
  transition: transform .5s, width .2s;
  transition-delay: .2s;
}

.ta-sidebar.active {
  transform: translateX(184px);
}

.ta-sidebar.medium {
  width: 720px;
}

.ta-sidebar.big {
  width: 960px;
}

.ta-sidebar.large {
  width: 1200px;
}

.ta-sidebar.active.icon-only {
  transform: translateX(60px);
}

.ta-sidebar__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background: #fff;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.3);
  z-index: 2;
  padding: 0 15px;
}

/* .ta-sidebar__content:after {
  content: "";
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  z-index: 899;
  pointer-events: none;
  background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(244,243,243,1) 90%);
  background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(244,243,243,1) 90%);
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(244,243,243,1) 90%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f4f3f3',GradientType=0 );
} */

.ta-sidebar__content.no-bottom-fade:after {
  content: none;
}

.ta-sidebar__close-button {
  position: absolute;
  top: 0;
  border-radius: 0 3px 3px 0;
  width: 32px;
  height: 51px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 32px;
  text-align: center;
  color: #fff;
  background-color: #505a5e;
  transition: transform .2s;
  transition-delay: .1s;
  z-index: 1;
  cursor: pointer;
}

/* LEFT FIRST LEVEL SIDEBAR */

.ta-sidebar.ta-sidebar__left-1 .ta-sidebar__close-button,
.ta-sidebar.ta-sidebar__left-2 .ta-sidebar__close-button {
  left: 100%;
  transform: translateX(-100%);
}

.ta-sidebar.ta-sidebar__left-1 .ta-sidebar__content-close-button {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10000;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;
  background: #fff;
  background: -moz-linear-gradient(left,  rgba(244,243,243,1) 0%, rgba(244,243,243,1) 50%, rgba(244,243,243,0.4) 100%);
  background: -webkit-linear-gradient(left,  rgba(244,243,243,1) 0%,rgba(244,243,243,1) 50%,rgba(244,243,243,0.4) 100%);
  background: linear-gradient(to right,  rgba(244,243,243,1) 0%,rgba(244,243,243,1) 50%,rgba(244,243,243,0.4) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f3f3', endColorstr='#00f4f3f3',GradientType=1 );  
}

.ta-sidebar.ta-sidebar__left-1.medium .ta-sidebar__content-close-button {
  background: -moz-linear-gradient(left, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 1) 60%, rgba(244, 243, 243, 0.4) 100%);
  background: -webkit-linear-gradient(left, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 1) 60%, rgba(244, 243, 243, 0.4) 100%);
  background: linear-gradient(to right, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 1) 60%, rgba(244, 243, 243, 0.4) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f3f3', endColorstr='#00f4f3f3', GradientType=1);
}

.ta-sidebar.ta-sidebar__left-1.big .ta-sidebar__content-close-button {
  background: -moz-linear-gradient(left, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 1) 80%, rgba(244, 243, 243, 0.4) 100%);
  background: -webkit-linear-gradient(left, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 1) 80%, rgba(244, 243, 243, 0.4) 100%);
  background: linear-gradient(to right, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 1) 80%, rgba(244, 243, 243, 0.4) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f3f3', endColorstr='#00f4f3f3', GradientType=1);
}

.ta-sidebar.ta-sidebar__left-1.big.child-large .ta-sidebar__content-close-button {
  background: -moz-linear-gradient(left, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 1) 92%, rgba(244, 243, 243, 0.4) 100%);
  background: -webkit-linear-gradient(left, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 1) 92%, rgba(244, 243, 243, 0.4) 100%);
  background: linear-gradient(to right, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 1) 92%, rgba(244, 243, 243, 0.4) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f3f3', endColorstr='#00f4f3f3', GradientType=1);
}

.ta-sidebar.ta-sidebar__left-1.active .ta-sidebar__close-button {
  transform: translateX(0);
}

.ta-sidebar.ta-sidebar__left-1.active.hide-close-button .ta-sidebar__close-button {
  transform: translateX(-30px);
}

.ta-sidebar.ta-sidebar__left-1.pending {
  transform: translateX(0px);
}

.ta-sidebar.ta-sidebar__left-1.pending.medium {
  transform: translateX(-320px);
}

.ta-sidebar.ta-sidebar__left-1.pending.big {
  transform: translateX(-560px);
}

.ta-sidebar.ta-sidebar__left-1.pending.big.child-large {
  transform: translateX(-760px);
}

.ta-sidebar.ta-sidebar__left-1.pending .ta-sidebar__content-close-button {
  visibility: visible;
  opacity: 1;
}

.ta-sidebar.ta-sidebar__left-1.active {
  transition-delay: 0s;
}


/* .ta-sidebar.ta-sidebar__right-1 .ta-simple-tabs__header:after,
.ta-sidebar.ta-sidebar__left-1 .ta-simple-tabs__header:after {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
} */

/* LEFT SECOND LEVEL SIDEBAR */

.ta-sidebar.ta-sidebar__left-2 {
  z-index: 999;
  transition-delay: 0s;
  transition: transform 0.8s, width 0.8s;
}

.ta-sidebar.ta-sidebar__left-2.active {
  transform: translateX(500px);
  transition-delay: .2s;
  transition: transform 0.8s, width 0.8s;
}

.ta-sidebar.ta-sidebar__left-2.large.active {
  transform: translateX(200px);
}

.ta-sidebar.ta-sidebar__left-2 .ta-sidebar__content {
  background: #fff;
}

.ta-sidebar.ta-sidebar__left-2.active .ta-sidebar__close-button {
  transform: translateX(0);
}

.ta-sidebar.ta-sidebar__left-2.active.hide-close-button .ta-sidebar__close-button {
  transform: translateX(-30px);
}

.ta-sidebar.ta-sidebar__left-2 .ta-sidebar__content:after {
  pointer-events: none;
  background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 90%);
  background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%);
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f4f3f3',GradientType=0 );
}

.ta-sidebar.ta-sidebar__left-2 .ta-fixed-content:before {
  pointer-events: none;
  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( endColorstr='#f4f3f3', startColorstr='#00ffffff',GradientType=0 );
}

/* RIGHT SIDEBAR */

.ta-sidebar.ta-sidebar__right-1 {
  left: auto;
  right: 0;
  transform: translateX(100%);
}

.ta-sidebar__right-1.active {
  transform: translateX(0);
}

.ta-sidebar.ta-sidebar__right-1 .ta-sidebar__content {
  box-shadow: rgba(0,0,0,0.2) -2px 0 3px 0;
}

.ta-sidebar__right-1 .ta-sidebar__close-button{
  right: 100%;
  transform: translateX(100%);
  border-radius: 3px 0 0 3px;
}

.ta-sidebar.ta-sidebar__right-1.active .ta-sidebar__close-button {
  transform: translateX(0); 
}

/* NEGATIVE */

.ta-sidebar__left-negative-1 {
  width: 440px;
  transition-delay: 0s;
  transition: transform .9s;
}

.ta-sidebar__left-negative-1.active.icon-only,
.ta-sidebar__left-negative-1.active {
  transform: translateX(0);
  transition: transform .5s;
}

.ta-sidebar__left-negative-1 .ta-sidebar__content {
  background: #fff;
}

.ta-sidebar__left-negative-1 .ta-sidebar__close-button {
  z-index: 10;
  top: 3px;
  right: 14px;
  background: transparent;
  color: #505a5e;
  font-size: 16px;
}