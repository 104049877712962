.ta-content {
  position: fixed;
  top: 0;
  left: 184px;
  right: 0;
  height: 100%;
  min-height: 550px;
  transform: translate3d(0, 0, 0);
  z-index: 3;
  transition: left 0.4s ease-out;
}

.ta-content.expanded {
  left: 175px;
}

.ta-content.collapsed {
  left: 0;
}

.ta-content.icon-only {
  left: 60px;
}


.ta-content.left {
  left: 485px;
  transition: left 1.1s ease-in;
}